import {
  INavLink, INavLinkGroup, Text, Nav, Stack, TextField,
  IStackStyles, Label, CommandBar, ICommandBarItemProps,
  PrimaryButton, DefaultButton, List, mergeStyleSets, NavBase
} from '@fluentui/react';
import { useBoolean } from "@fluentui/react-hooks";
import React, { useContext } from 'react';
import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom';
import { appRoles } from '../authConfig';
import ConfirmDialog from '../common/ConfirmDialog';
import { createCategory, editCategory, getAssetCategories, ICreateCategoryRequest, ICreateCategoryResponse, IUpdateCategoryRequest, IAssetCategory, IIsueType, getAssetCategoryIssueTypes } from '../services/assetServices';
import AppContext from './AppContext';

const Categories = () => {
  let { id } = useParams();

  const context = useContext(AppContext);

  const [categories, setCategories] = useState<INavLinkGroup[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<IAssetCategory>();
  const [inEditMode, setInEditMode] = useState(false);

  const [state, setState] = useState("");
  const savedCategory = useRef<IAssetCategory>();

  const loadCategories = (catId?: number) => {
    const abortController = new AbortController();

    getAssetCategories(abortController)
      .then((categories: IAssetCategory[]) => {
        const links: INavLink[] = [];
        const groups: INavLinkGroup[] = [{ name: 'Categories', links: links }];
        const catMap = new Map<number, IAssetCategory>();
        addChildCategories(links, categories, catMap);
        setCats(catMap);
        setCategories(groups);
        if (catId) {
          handleSelectionChange(catId);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setState(`Error: ${error}`);
      });
    setInEditMode(false);
    return () => {
      abortController.abort();
    }
  };

  const [assetCatIssueTypes, setAssetCatIssueTypes] = useState(new Map<number, IIsueType[]>());
  const [cats, setCats] = useState(new Map<number, IAssetCategory>());
  useEffect(() => {
    const catId = id ? Number.parseInt(id) : undefined;

    loadCategories(catId);
  }, [])

  const handleChange = (e: any) => {
    switch (e.target.name) {
      case 'code':
        setSelectedCategory((prevItem: any) => ({ ...prevItem, code: e.target.value }));
        break;
      case 'name':
        setSelectedCategory((prevItem: any) => ({ ...prevItem, name: e.target.value }));
        break;
      default:
        break;
    }
  };

  const handleSave = () => {
    setState("Saving the item...");
    const abortController = new AbortController();

    if (selectedCategory?.id) {
      const updateCategoryRequest: IUpdateCategoryRequest = {
        code: selectedCategory?.code,
        name: selectedCategory?.name,
        parentId: Number(selectedCategory?.parentId),
      };

      editCategory(abortController, selectedCategory?.id?.toString() ?? '', updateCategoryRequest)
        .then((data: number) => {
          setState("Category successfully updated");
          setSelectedCategory({
            id: data,
            code: updateCategoryRequest.code ?? '',
            name: updateCategoryRequest.name ?? '',
            parentId: updateCategoryRequest.parentId ?? 0
          });
          loadCategories();
        })
        .catch((error) => {
          console.error("Error:", error);
          setState(`Error: ${error}`);
        });
    } else {
      const createCategoryRequest: ICreateCategoryRequest = {
        name: selectedCategory?.name ?? '',
        code: selectedCategory?.code ?? '',
        parentId: selectedCategory?.parentId
      };
      createCategory(abortController, createCategoryRequest)
        .then((data: ICreateCategoryResponse) => {
          setState(`Category successfully created ${data.assetCategoryId}`);
          loadCategories();
        })
        .catch((error) => {
          console.error("Error:", error);
          setState(`Error: ${error}`);
        });
    }
  }

  const handleSelectionChange = (selectedCatId: number) => {
    const issueTypes = assetCatIssueTypes.get(selectedCatId);
    if (!cats.has(selectedCatId)) {
      return;
    }
    const cat: IAssetCategory = cats.get(selectedCatId) ?? { code: '', name: '' };
    if (!issueTypes) {
      const abortController = new AbortController();
      getAssetCategoryIssueTypes(abortController, selectedCatId.toString())
        .then((issueTypes: IIsueType[]) => {
          setSelectedCategory({ ...cat, issueTypes: issueTypes });
          setAssetCatIssueTypes(new Map(assetCatIssueTypes?.set(cat.id ?? 0, issueTypes)));
        })
    } else {
      setSelectedCategory({ ...cat, issueTypes: issueTypes });
    }
  }

  const stackStyles: IStackStyles = {
    root: {
      width: `${25}%`,
    },
  };

  const _items: ICommandBarItemProps[] = [
    {
      key: "newCategory",
      text: "New",
      iconProps: { iconName: "Add" },
      disabled: !context.profileData.roles.includes(appRoles.Admin),
      onClick: () => {
        setSelectedCategory((prevItem: any) => ({
          ...prevItem,
          name: '', code: '', id: 0, parentId: selectedCategory?.id
        }));

        savedCategory.current = { code: '', name: '' };
        setInEditMode(true);
        setState('');
      },
    },
    {
      key: "categoryEdit",
      text: "Edit",
      iconProps: { iconName: "Edit" },
      onClick: () => {
        setInEditMode(true);
        setState('');
        savedCategory.current = { code: selectedCategory?.code || '', name: selectedCategory?.name || '' };
      },
      disabled: !context.profileData.roles.includes(appRoles.Admin) || !selectedCategory,
    },
    {
      key: "categoryDelete",
      text: "Delete",
      iconProps: { iconName: "Delete" },
      onClick: () => {
      },
      disabled: !context.profileData.roles.includes(appRoles.Admin) || !selectedCategory,
    },
  ];

  const stackTokens = { childrenGap: 50 };

  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] =
    useBoolean(false);

  const classNames = mergeStyleSets({
    issueTypeCode: {
      display: 'flex',
      width: 150,
    },
    issueTypeName: {
      display: 'flex',
      width: 200
    }
  });

  const onRenderCell = React.useCallback((issueType?: IIsueType) => {
    return (
      <Stack horizontal>
        <span className={classNames.issueTypeCode}>{issueType?.code}</span>
        <span style={{ width: 20 }}></span>
        <span className={classNames.issueTypeName}>{issueType?.name}</span>
      </Stack>
    );
  }, [classNames.issueTypeCode, classNames.issueTypeName]);

  const navRef = useRef<NavBase>(null);

  return (
    <Stack>
      <Stack horizontal>
        <Stack.Item styles={stackStyles}>
          <Nav groups={categories}
            componentRef={navRef}
            onLinkClick={(e, item) => {
              if (!item?.key) {
                return;
              }
              const catId = Number.parseInt(item.key);
              handleSelectionChange(catId);
            }} />
        </Stack.Item>
        <Stack>
          <CommandBar
            items={_items}
            ariaLabel="Items actions"
            primaryGroupAriaLabel="Items actions"
            farItemsGroupAriaLabel="More actions"
          />
          <Stack tokens={{ childrenGap: 5 }} style={{ display: selectedCategory ? '' : 'none' }}>
            <Text variant="large" >Properties</Text>
            <Stack horizontal>
              <Label style={{ width: '75px' }}>Name: </Label>
              <TextField value={selectedCategory?.name} readOnly={!inEditMode}
                name="name"
                onChange={handleChange} />
            </Stack>
            <Stack horizontal>
              <Label style={{ width: '75px' }}>Code: </Label>
              <TextField value={selectedCategory?.code} readOnly={!inEditMode}
                name="code"
                onChange={handleChange} />
            </Stack>
            <Stack>
              <Text variant='large'>Issue types</Text>
              <Stack horizontal>
                <Text variant='mediumPlus' className={classNames.issueTypeCode}>Code</Text>
                <span style={{ width: 20 }}></span>
                <Text variant='mediumPlus' className={classNames.issueTypeName}>Name</Text>
              </Stack>
              <List
                items={selectedCategory?.issueTypes}
                onRenderCell={onRenderCell}
              />
            </Stack>
            <Stack>
              <Stack.Item align="center" style={{ display: inEditMode ? '' : 'none' }}>
                <Stack horizontal tokens={stackTokens}>
                  <PrimaryButton onClick={() => { handleSave() }}>
                    Save
                  </PrimaryButton>
                  <DefaultButton onClick={() => {
                    if (savedCategory.current?.code !== selectedCategory?.code ||
                      savedCategory.current?.name !== selectedCategory?.name) {
                      showModal();
                    } else {
                      setInEditMode(false);
                      setState("");
                    }
                  }}>Cancel</DefaultButton>
                </Stack>
              </Stack.Item>
              <span>{state}</span>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <ConfirmDialog isModalOpen={isModalOpen} hideModal={hideModal}
        message="All your unsaved changes would be lost."
        onYesClick={() => {
          hideModal();
          setSelectedCategory(savedCategory.current);
          setInEditMode(false);
        }} />

    </Stack>
  )
}

export default Categories

function addChildCategories(links: INavLink[], subCategories: IAssetCategory[], catMap: Map<number, IAssetCategory>) {
  for (const childCategory of subCategories) {
    const navLink: INavLink = {
      name: childCategory.name, url: '',
      key: childCategory.id?.toString(),
    };
    if (childCategory.id) {
      catMap.set(childCategory.id ?? 0, childCategory);
    }
    if (childCategory.subCategories) {
      const subLinks: INavLink[] = [];
      addChildCategories(subLinks, childCategory.subCategories, catMap);
      navLink.links = subLinks;
    }
    links.push(navLink);
  }
}
