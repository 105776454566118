import { IList, List, ScrollToMode } from '@fluentui/react';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { useEffect, useState } from 'react'
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import React from 'react';

interface IMessage {
  l: Number;
  b: string
}
const ImportLogs = () => {

  const [connection, setConnection] = useState<null | HubConnection>(null);
  useEffect(() => {
    const connect = new HubConnectionBuilder()
      .withUrl(`${process.env.REACT_APP_NOTIF_API_URL}`)
      .withAutomaticReconnect()
      .build();

    setConnection(connect);
  }, []);

  const [isConnectedToHub, setIsConnectedToHub] = useState(false);
  const [items, setItems] = useState<IMessage[]>([{ l: 1, b: "Connecting to hub." }]);

  const listRef: React.RefObject<IList> = React.useRef(null);

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then(() => {
          connection.on("newMessage", (messageText) => {
            console.log(messageText);
            const message: IMessage = JSON.parse(messageText);
            setItems(prevList => {
              listRef.current?.scrollToIndex(
                prevList.length + 1,
                idx => (15),
                ScrollToMode.bottom
              );
              return [...prevList, { l: message.l, b: message.b }];
            });
          });
        })
        .then(async () => {
          if (connection) {
            setItems(prevList => [...prevList, { l: 1, b: "Connected" }]);
            setIsConnectedToHub(true);

          }
        })
        .catch((error) => {
          setIsConnectedToHub(false);
          setItems(prevList => [...prevList, { l: 1, b: "Could not connect" }]);
          console.log(error);
        });
    }
  }, [connection]);

  const classNames = mergeStyleSets({
    list: {
      height: 100,
      overflow: 'auto',
    },
    itemContent: {
      marginLeft: 10,
      overflow: 'hidden',
      display: 'flex',
      flexGrow: 1,
      backgroundColor: 'black',
      fontFamily: 'monospace',
    },
    itemCell: {
      color: 'white',
    },
    itemCellWithError: {
      color: 'red'
    },
    itemIndex: {
      width: '50px',
      color: 'gray',
    }
  });

  const onRenderCell = (item?: IMessage, index?: number | undefined, isScrolling?: boolean | undefined): JSX.Element => {
    return (
      <div className={classNames.itemContent}>
        <div className={classNames.itemIndex}>{(index ?? 0) + 1}</div>
        <div className={(item?.l ?? 1) > 0 ? classNames.itemCell : classNames.itemCellWithError}>{item?.b}</div>
      </div>
    );
  };
  return (
    <>
      <List items={items} onRenderCell={onRenderCell} className={classNames.list}
        componentRef={listRef}
      />
    </>
  )
}

export default ImportLogs