import {
  INavLink, INavLinkGroup, Text, Nav, Stack, TextField,
  IStackStyles, Label, CommandBar, ICommandBarItemProps,
  PrimaryButton, DefaultButton, Checkbox, mergeStyleSets
} from '@fluentui/react';
import { useBoolean } from "@fluentui/react-hooks";
import _ from 'lodash';
import { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { appRoles } from '../authConfig';
import ConfirmDialog from '../common/ConfirmDialog';
import { createZone, deleteZone, editZone, getAssetCategories, getZones, IAssetCategory, ICreateZoneRequest, ICreateZoneResponse, IUpdateZoneRequest, IZone } from '../services/assetServices';
import AppContext from './AppContext';

const Zones = () => {
  const [zones, setZones] = useState<INavLinkGroup[]>([]);
  const [selectedZone, setSelectedZone] = useState<IZone>();
  const [inEditMode, setInEditMode] = useState(false);
  const [inDeleteMode, setInDeleteMode] = useState(false);

  const [state, setState] = useState("");
  const [confirmDialogMessage, setConfirmDialogMessage] = useState("");
  const savedZone = useRef<IZone>();

  const context = useContext(AppContext);
  const navigate = useNavigate();

  const [categories, setCategories] = useState<IAssetCategory[]>([]);

  const loadZones = () => {
    const abortController = new AbortController();

    context.setIsInProgress(true);
    getAssetCategories(abortController)
      .then((data: IAssetCategory[]) => {
        const flattenList: IAssetCategory[] = [];
        flattenTree(data, flattenList, '');
        setCategories(flattenList);
        getZones(abortController, '')
          .then((zones: IZone[]) => {
            const links: INavLink[] = [];
            const groups: INavLinkGroup[] = [{ name: 'Zones', links: links }];
            addChildZones(links, zones);
            setZones(groups);
          })
          .catch((error) => {
            console.error("Error:", error);
            setState(`Error: ${error}`);
          });
      })
      .catch((error) => {
        console.error("Error:", error);
        context.setErrorMessage(error.message);
      })
      .finally(() => {
        context.setIsInProgress(false);
      });

    setInEditMode(false);
    return () => {
      abortController.abort();
    }
  };
  useEffect(() => loadZones(), [])

  const handleChange = (e: any) => {
    switch (e.target.name) {
      case 'code':
        setSelectedZone((prevItem: any) => ({ ...prevItem, code: e.target.value }));
        break;
      case 'name':
        setSelectedZone((prevItem: any) => ({ ...prevItem, name: e.target.value }));
        break;
      default:
        break;
    }
  };

  const handleCategoriesChange = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
    if (!inEditMode) {
      return;
    }

    const catId: number = ((ev?.target as any).id);
    let newAssetCategories: IAssetCategory[] = _.cloneDeep(selectedZone?.assetCategories) ?? [];
    if (checked) {
      // eslint-disable-next-line eqeqeq
      const cat = categories.find(cat => cat.id == catId);
      if (cat) {
        newAssetCategories.push(cat);
      }
    } else {
      // eslint-disable-next-line eqeqeq
      _.remove(newAssetCategories, aCat => aCat.id == catId);
    }
    setSelectedZone({ ...selectedZone, name: selectedZone?.name, assetCategories: newAssetCategories });
  }

  const handleSave = () => {
    setState("Saving the item...");
    const abortController = new AbortController();

    if (selectedZone?.id) {
      const updateZoneRequest: IUpdateZoneRequest = {
        code: selectedZone?.code,
        name: selectedZone?.name,
        parentId: undefined, //Number(selectedZone?.categoryId),
        assetCategories: selectedZone?.assetCategories?.filter(ac => ac.id).map(ac => ac.id ?? 0) ?? [],
      };

      editZone(abortController, selectedZone?.id?.toString() ?? '', updateZoneRequest)
        .then((data: number) => {
          setState("Zone successfully updated");
          setSelectedZone({
            id: data,
            code: updateZoneRequest.code ?? '',
            name: updateZoneRequest.name ?? '',
            // parentId: updateZoneRequest.parentId ?? 0
            assetCategories: selectedZone?.assetCategories
          });
          loadZones();
        })
        .catch((error) => {
          console.error("Error:", error);
          setState(`Error: ${error}`);
        });
    } else {
      const createZoneRequest: ICreateZoneRequest = {
        name: selectedZone?.name ?? '',
        code: selectedZone?.code ?? '',
        parentId: selectedZone?.parentId,
        assetCategories: selectedZone?.assetCategories?.map(aCat => aCat.id || 0).filter(id => id > 0),
      };
      createZone(abortController, createZoneRequest)
        .then((data: ICreateZoneResponse) => {
          setState(`Zone successfully created ${data.zoneId}`);
          loadZones();
        })
        .catch((error) => {
          console.error("Error:", error);
          setState(`Error: ${error}`);
        });
    }
  }

  const handleDelete = () => {
    if (selectedZone?.id) {
      const abortController = new AbortController();
      deleteZone(abortController, selectedZone?.id?.toString())
        .then((data: number) => {
          hideModal();
          setState(`Zone ${data} deleted`);
          loadZones();
        })
        .catch((error) => {
          console.error("Error:", error);
          setState(`Error: ${error}`);
        })
        .finally(() => {
          setInDeleteMode(false);
        });
    }
  }

  const stackStyles: IStackStyles = {
    root: {
      width: `${25}%`,
    },
  };

  const _items: ICommandBarItemProps[] = [
    {
      key: "showQR",
      text: "Show QR",
      iconProps: { iconName: "QRCode" },
      onClick: () => {
        navigate(`/showQR?zoneId=${selectedZone?.id}`);
      },
      disabled: !selectedZone,
    },
    {
      key: "newZone",
      text: "New",
      iconProps: { iconName: "Add" },
      disabled: !context.profileData.roles.includes(appRoles.Admin),
      onClick: () => {
        setSelectedZone((prevItem: any) => ({
          ...prevItem,
          name: '', code: '', id: 0, parentId: selectedZone?.id, assetCategories: []
        }));
        savedZone.current = { code: '', name: '' };
        setInEditMode(true);
      },
    },
    {
      key: "zoneEdit",
      text: "Edit",
      iconProps: { iconName: "Edit" },
      onClick: () => {
        setInEditMode(true);
        savedZone.current = { code: selectedZone?.code || '', name: selectedZone?.name || '' };
      },
      disabled: !context.profileData.roles.includes(appRoles.Admin) || !selectedZone,
    },
    {
      key: "zoneDelete",
      text: "Delete",
      iconProps: { iconName: "Delete" },
      onClick: () => {
        setInDeleteMode(true);
        setConfirmDialogMessage("The Zone would be deleted");
        showModal();
      },
      disabled: !context.profileData.roles.includes(appRoles.Admin) ||  !selectedZone,
    },
  ];

  const stackTokens = { childrenGap: 50 };

  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] =
    useBoolean(false);


  const classNames = mergeStyleSets({
    CheckboxListContainer: {
      height: 500,
      overflow: 'auto',
    },
    ZonesListContainer: {
      height: 620,
      overflow: 'auto',
    },
    CheckboxItem: {
      height: 24,
    }
  });
  return (
    <Stack>
      <Stack horizontal>
        <Stack.Item styles={stackStyles}>
          <div className={classNames.ZonesListContainer} >
            <Nav groups={zones} onLinkClick={(e, item) => {
              if (!item?.key) {
                return;
              }
              setSelectedZone(JSON.parse(item?.key));
            }} />
          </div>
        </Stack.Item>
        <Stack>
          <CommandBar
            items={_items}
            ariaLabel="Items actions"
            primaryGroupAriaLabel="Items actions"
            farItemsGroupAriaLabel="More actions"
          />
          <Stack tokens={{ childrenGap: 5 }} style={{ display: selectedZone ? '' : 'none' }}>
            <Text variant="large" >Properties</Text>
            <Stack horizontal>
              <Label style={{ width: '75px' }}>Id: </Label>
              <TextField value={!inEditMode ? selectedZone?.id?.toString() : ' '} readOnly={true}
                borderless
                name="name"
                onChange={handleChange} />
            </Stack>
            <Stack horizontal>
              <Label style={{ width: '75px' }}>Name: </Label>
              <TextField value={selectedZone?.name} readOnly={!inEditMode}
                name="name"
                onChange={handleChange} />
            </Stack>
            <Stack horizontal>
              <Label style={{ width: '75px' }}>Code: </Label>
              <TextField value={selectedZone?.code} readOnly={!inEditMode}
                name="code"
                onChange={handleChange} />
            </Stack>
            <Text variant='large'>Asset Categories</Text>
            <div className={classNames.CheckboxListContainer} >
              {categories.map(cat => (
                <Stack horizontal>
                  <span className={classNames.CheckboxItem}
                    style={{ width: (cat.name.indexOf('/') * 14) }}>
                  </span>
                  <Checkbox
                    id={cat.id?.toString()}
                    label={cat.name.substring(cat.name.indexOf('/') + 1)}
                    checked={!!selectedZone?.assetCategories?.find(ac => ac.id === cat.id)}
                    onChange={handleCategoriesChange}
                  />
                </Stack>
              ))}
            </div>
            <Stack>
              <Stack.Item align="center" style={{ display: inEditMode ? '' : 'none' }}>
                <Stack horizontal tokens={stackTokens}>
                  <PrimaryButton onClick={() => { handleSave() }}>
                    Save
                  </PrimaryButton>
                  <DefaultButton onClick={() => {
                    if (savedZone.current?.code !== selectedZone?.code ||
                      savedZone.current?.name !== selectedZone?.name) {
                      setConfirmDialogMessage("All your unsaved changes would be lost.");
                      showModal();
                    } else {
                      setInEditMode(false);
                      setState("");
                    }
                  }}>Cancel</DefaultButton>
                </Stack>
              </Stack.Item>
              <span>{state}</span>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <ConfirmDialog isModalOpen={isModalOpen} hideModal={hideModal}
        message={confirmDialogMessage}
        onYesClick={() => {
          if (inDeleteMode) {
            handleDelete();
            return;
          }
          hideModal();
          setSelectedZone(savedZone.current);
          setInEditMode(false);
        }} />

    </Stack>
  )
}

export default Zones

function addChildZones(links: INavLink[], childZones: IZone[]) {
  for (const childZone of childZones) {
    const navLink: INavLink = {
      name: childZone.name || '', url: '',
      key: JSON.stringify({
        id: childZone.id, code: childZone.code, name: childZone.name,
        parentId: childZone.parentId, assetCategories: childZone.assetCategories
      })
    };
    if (childZone.childZones) {
      const subLinks: INavLink[] = [];
      addChildZones(subLinks, childZone.childZones);
      navLink.links = subLinks;
    }
    links.push(navLink);
  }
}

function flattenTree(cats: IAssetCategory[], result: IAssetCategory[], space: string) {
  for (const cat of cats) {
    result.push({ ...cat, name: `${space}/${cat.name}` });
    if (cat.subCategories) {
      flattenTree(cat.subCategories, result, '—' + space);
    }
  }
  return result;
}
