import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import {
  CommandBar,
  ICommandBarItemProps,
  INavLinkGroup,
  INavStyles,
  Nav,
  Text,
  Stack,
  ITextStyles,
  mergeStyles,
  MessageBar,
  MessageBarType,
  ProgressIndicator,
  IProgressIndicatorStyles,
  INavLink,
} from "@fluentui/react";
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { idp, idps, loginRequest } from "../authConfig";
import { getBuildNumber } from "../services/assetServices";
import { getProfileData } from "../services/graph";
import AppContext, { IProfileData } from "./AppContext";
import { useBoolean } from "@fluentui/react-hooks";
import InfoDialog from "../common/InfoDialog";
import logo from '../assets/MoKoLogo.svg'

const Home = (props: any) => {
  let navigate = useNavigate();
  const context = useContext(AppContext);

  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const [navExpanded, setNavExpanded] = useState(true);
  const [activeNav, setActiveNav] = useState("home");

  const _items: ICommandBarItemProps[] = [];

  const [isAboutModalOpen, { setTrue: showAboutModal, setFalse: hideAboutModal }] =
    useBoolean(false);
  const [expandedNav, setExpandedNav] = useState('');

  const [buildNumber, setBuildNumber] = useState("");

  const handleSignIn = () => {
    instance.loginRedirect(loginRequest).catch((e) => {
      console.error(e);
    });
  };

  const handleAboutClick = () => {
    const abortController = new AbortController();
    getBuildNumber(abortController)
      .then((buildNumber: string) => {
        setBuildNumber(buildNumber);
        showAboutModal();
      })
      .catch((reason: any) => {
        console.log(reason);
        setBuildNumber("Failed to fetch API Build number");
        showAboutModal();
      });;
  }

  useEffect(() => {
    if (idp === idps.DB) {
      return;
    }
    getProfileData(instance, accounts)
      .then((profileData: IProfileData) => {
        context.setProfileData(profileData);
      })
  }, [])

  const [isConnectedToHub, setIsConnectedToHub] = useState(false);

  const _farItems: ICommandBarItemProps[] = [
    {
      key: "connectionStatus",
      text: "Connection Status",
      ariaLabel: "Connection Status",
      iconOnly: true,
      iconProps: { iconName: isConnectedToHub ? "PlugConnected" : "PlugDisconnected" }
    },
    !(isAuthenticated || context.isAuthenticated)
      ? {
        key: "login",
        text: "Login",
        ariaLabel: "Login",
        iconOnly: true,
        iconProps: { iconName: "FollowUser" },
        onClick: handleSignIn,
      }
      : {
        key: "signedIn",
        text: `${context.profileData.givenName} ${context.profileData.surname}`,
        // This needs an ariaLabel since it's icon-only
        ariaLabel: "Signed In",
        iconOnly: true,
        iconProps: { iconName: "Contact" },
        subMenuProps: {
          items: [
            {
              key: "profile",
              text: "Profile",
              ariaLabel: "Profile",
              onClick: async () => {
                context.setIsProfileDialogOpen(true);
              },
            },
            {
              key: "logout",
              text: "Logout",
              onClick: () => {
                instance.logoutRedirect().catch((e) => {
                  console.error(e);
                });
              },
            },
            {
              key: "about",
              text: "About",
              ariaLabel: "About",
              onClick: handleAboutClick,
            },
          ],
        },
      },
  ];

  const navLinkGroups: INavLinkGroup[] = [
    {
      links: [
        {
          name: "",
          url: "",
          icon: navExpanded ? "DoubleChevronLeft8" : "DoubleChevronRight8",
          onClick: () => {
            if (navExpanded) {
              setNavExpanded(false);
            } else {
              setNavExpanded(true);
            }
          },
        },
        {
          name: "Assets",
          url: "",
          expandAriaLabel: "Assets list",
          collapseAriaLabel: "Assets list",
          icon: "Home",
          key: "assets",
          onClick: () => {
            context.setErrorMessage("");
            context.setSelectedItem({ id: 0, uuid: "" });
            setActiveNav("assets");
            navigate("/");
          },
        },
        {
          name: "Zones",
          url: "",
          expandAriaLabel: "Zones list",
          collapseAriaLabel: "Zones list",
          icon: "EngineeringGroup",
          key: "zones",
          onClick: () => {
            context.setErrorMessage("");
            context.setSelectedItem({ id: 0, uuid: "" });
            setActiveNav("zones");
            navigate("/zones");
          },
        },
        {
          name: "Categories",
          url: "",
          expandAriaLabel: "Asset Categories",
          collapseAriaLabel: "Asset Categories",
          icon: "GroupList",
          key: "categories",
          onClick: () => {
            context.setErrorMessage("");
            context.setSelectedItem({ id: 0, uuid: "" });
            setActiveNav("categories");
            navigate("/categories");
          },
        },
        {
          name: "Contractors",
          url: "",
          expandAriaLabel: "Contractors",
          collapseAriaLabel: "Contractors",
          icon: "AccountManagement",
          key: "contractors",
          onClick: () => {
            context.setErrorMessage("");
            context.setSelectedItem({ id: 0, uuid: "" });
            setActiveNav("contractors");
            navigate("/contractors");
          },
        },
        {
          name: "Issues",
          url: "",
          icon: "IssueSolid",
          key: "issues",
          onClick: () => {
            context.setErrorMessage("");
            setActiveNav("issues");
            navigate("/issues");
          }
        },
        {
          name: "Work Orders",
          url: "",
          icon: "WorkItem",
          key: "workOrders",
          isExpanded: false,
          onClick: () => {
            context.setErrorMessage("");
            context.setSelectedItem({ id: 0, uuid: "" });
            setActiveNav("workOrders");
            navigate(`/workOrders`);
          },
        },
        {
          name: "QR Codes",
          url: "",
          icon: "QRCode",
          key: "qrCodes",
          onClick: () => {
            context.setErrorMessage("");
            setActiveNav("qrCodes");
            navigate("/qrCodes");
          },
        },
        {
          name: 'References',
          key: 'references',
          url: '',
          isExpanded: expandedNav === 'references',
          links: [
            {
              name: 'Statuses',
              key: 'workOrderStatuses',
              url: '',
              onClick: () => {
                context.setErrorMessage("");
                setActiveNav("workOrderStatuses");
                navigate("/workOrderStatuses");
              },
            }
          ],
        },
        {
          name: "Import",
          key: "import",
          url: "",
          isExpanded: expandedNav === 'import',
          links: [
            {
              name: "Assets",
              url: "",
              icon: "ImportAllMirrored",
              key: "importAssets",
              target: "_blank",
              onClick: () => {
                context.setErrorMessage("");
                setActiveNav("importAssets");
                navigate("/importAssets");
              },
            },
            {
              name: "Issue types",
              url: "",
              icon: "DefectSolid",
              key: "importIssueTypes",
              target: "_blank",
              onClick: () => {
                context.setErrorMessage("");
                setActiveNav("importIssueTypes");
                navigate("/importIssueTypes");
              },
            },
            {
              name: "zones",
              url: "",
              icon: "EngineeringGroup",
              key: "importZones",
              target: "_blank",
              onClick: () => {
                context.setErrorMessage("");
                setActiveNav("importZones");
                navigate("/importZones");
              },
            },
          ]
        },
        {
          name: "Settings",
          url: "",
          icon: "Settings",
          key: "settings",
          onClick: () => {
            context.setErrorMessage("");
            setActiveNav("settings");
            navigate("settings");
          },
        },
      ],
    },
  ];

  const navStyles: Partial<INavStyles> = {
    root: {
      width: navExpanded ? 180 : 42,
      boxSizing: "border-box",
      border: "1px solid #eee",
      overflowY: "auto",
    },
  };

  const titleTextStyles: Partial<ITextStyles> = {
    root: {
      paddingLeft: "14px",
      paddingTop: "14px",
    },
  };

  const subtitleTextStyles: Partial<ITextStyles> = {
    root: {
      paddingLeft: "5px",
    },
  };
  const logoBackground = mergeStyles({
    backgroundImage: "linear-gradient(to right, lightblue, #FFFFFF)",
  });

  const progressBarStyles: IProgressIndicatorStyles = {
    itemDescription: "",
    itemName: "",
    itemProgress: { padding: "0px 0px" },
    progressBar: "",
    progressTrack: "",
    root: {},
  };

  const [connection, setConnection] = useState<null | HubConnection>(null);
  useEffect(() => {
    const connect = new HubConnectionBuilder()
      .withUrl(`${process.env.REACT_APP_NOTIF_API_URL}`)
      .withAutomaticReconnect()
      .build();

    setConnection(connect);
  }, []);

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then(() => {
          connection.on("newMessage", (message) => {
            console.log(message);
          });
        })
        .then(async () => {
          if (connection) {
            setIsConnectedToHub(true);
            await connection.send("sendMessage", "echo");
          }
        })
        .catch((error) => {
          setIsConnectedToHub(false);
          console.log(error);
        });
    }
  }, [connection]);

  return (
    <div className="App">
      <Stack
        horizontal
        styles={{
          root: {
            backgroundImage: "linear-gradient(to right, lightblue, #FFFFFF)",
          },
        }}
      >
        <Stack.Item className={logoBackground}>
          <Text variant="xxLarge" styles={titleTextStyles}>
            FMS
          </Text>
          <Text styles={subtitleTextStyles}>Fix my services</Text>
        </Stack.Item>
        <Stack.Item grow>
          <CommandBar
            className="Header"
            items={_items}
            farItems={_farItems}
            ariaLabel="Items actions"
            primaryGroupAriaLabel="Items actions"
            farItemsGroupAriaLabel="More actions"
          />
        </Stack.Item>
      </Stack>
      <Stack>
        <ProgressIndicator
          styles={progressBarStyles}
          percentComplete={!context.isInProgress ? 0 : undefined}
        />
        <Stack horizontal>
          <Nav
            // onLinkClick={_onLinkClick}
            selectedKey={activeNav}
            ariaLabel="Nav"
            styles={navStyles}
            groups={navLinkGroups}
            onLinkExpandClick={(_, item?: INavLink) => {
              setExpandedNav(item?.key ?? '');
            }}
          />
          <Stack.Item grow style={{ overflowY: 'scroll', display: 'flex' }}>
            {context.errorMessage ? (
              <MessageBar
                messageBarType={MessageBarType.error}
                isMultiline={false}
              >
                {context.errorMessage}
              </MessageBar>
            ) : (
              <></>
            )}
            {props.children}
          </Stack.Item>
        </Stack>
      </Stack>
      <InfoDialog isModalOpen={isAboutModalOpen} hideModal={hideAboutModal} message={<>
        <p>
          <img src={logo} alt="MoKo Solution logo" style={{
            width: 50,
          }} />
        </p>
        <p>{`Portal API build number: ${buildNumber}`}</p>
        <p>{`Portal UI build number:${process.env.REACT_APP_BUILD_NUMBER}`}</p>
      </>
      } />
    </div>
  );
};

export default Home;
