import "./CreateItem.css";
import { useContext, useEffect, useRef, useState } from "react";
import {
  DefaultButton,
  PrimaryButton,
  Label,
  Dropdown,
  IDropdown,
} from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { ITextField, TextField } from "@fluentui/react/lib/TextField";
import { Stack, IStackProps, IStackStyles } from "@fluentui/react/lib/Stack";
import { useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { createAsset, getAssetCategories, IAssetCategory, ICreateAssetRequest } from "../services/assetServices";
import AppContext from "./AppContext";
import ConfirmDialog from "../common/ConfirmDialog";

const CreateItem = () => {
  const [categories, setCategories] = useState<IAssetCategory[]>();
  const navigate = useNavigate();

  const codeInput = useRef(null);
  const noteInput = useRef(null);
  const categoryInput = useRef(null);
  const [state, setState] = useState("");
  const isSaved = useRef(false);

  const context = useContext(AppContext);

  useEffect(() => {
    const abortController = new AbortController();
    context.setIsInProgress(true);
    getAssetCategories(abortController)
      .then((data: IAssetCategory[]) => {
        setCategories(data);
      })
      .catch((error) => {
        console.error("Error:", error);
        context.setErrorMessage(error.message);
      })
      .finally(() => {
        context.setIsInProgress(false);
      });
    return () => {
      abortController.abort();
    }
  }, []);

  const handleSubmit = (event: any) => {
    if (event) {
      event.preventDefault();
    }
    const date = new Date();
    const data: ICreateAssetRequest = {
      uuid: uuid(),
      code: (codeInput?.current as unknown as ITextField).value ?? '',
      note: (noteInput?.current as unknown as ITextField).value ?? '',
      categoryId: Number((categoryInput?.current as unknown as IDropdown).selectedOptions[0].key),
      changedate: date.toISOString()
    };
    const abortController = new AbortController();
    context.setIsInProgress(true);
    createAsset(abortController, data)
      .then((data) => {
        setState(`${data.created ? "Asset created." : "Asset with this code already exists"} Asset ID:${data.assetId} QR Code Id: ${data.qrCodeId}`);
        isSaved.current = true;
      })
      .catch((error) => {
        console.error("Error:", error);
        context.setErrorMessage(error.message);
      })
      .finally(() => {
        context.setIsInProgress(false);
      });
  };

  const stackTokens = { childrenGap: 50 };
  const stackStyles: Partial<IStackStyles> = { root: { width: 650 } };
  const columnProps: Partial<IStackProps> = {
    tokens: { childrenGap: 15 },
    styles: { root: { width: 300 } },
  };

  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] =
    useBoolean(false);

  const labelColumnStyle = {
    width: 125,
    textAlign: 'left' as const
  };
  const valueColumnStyle = {
    width: 250,
  }
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div>
          <div className="form-group">
            <Stack horizontal tokens={stackTokens} styles={stackStyles}>
              <Stack {...columnProps}>
                <Stack tokens={{ childrenGap: 5 }}>
                  <Stack horizontal>
                    <Label style={labelColumnStyle}>Code: </Label>
                    <TextField style={valueColumnStyle} componentRef={codeInput} readOnly={isSaved.current} />
                  </Stack>
                  <Stack horizontal>
                    <Label style={labelColumnStyle}>Note: </Label>
                    <TextField style={valueColumnStyle} componentRef={noteInput} readOnly={isSaved.current} />
                  </Stack>
                  <Stack horizontal>
                    <Label style={labelColumnStyle}>Category: </Label>
                    <Dropdown
                      // options={[{ key: 1, text: 'General' }, { key: 2, text: 'Cleaning' }]}
                      options={categories?.map(cat => ({ key: cat.id ?? '', text: cat.name })) ?? []}
                      defaultSelectedKey={1}
                      componentRef={categoryInput}
                      disabled={isSaved.current}
                    />
                  </Stack>
                </Stack>
                <Stack.Item align="center">
                  <Stack horizontal tokens={stackTokens}>
                    <PrimaryButton type="submit">Save</PrimaryButton>
                    <DefaultButton onClick={() => {
                      if (!isSaved.current) {
                        showModal();
                      } else {
                        navigate("/");
                      }
                    }}>Cancel</DefaultButton>
                  </Stack>
                </Stack.Item>
              </Stack>
            </Stack>

            <ConfirmDialog isModalOpen={isModalOpen} hideModal={hideModal}
              message="All your unsaved changes would be lost."
              onYesClick={() => navigate("/")} />

          </div>
        </div>
      </form>{" "}
      <span>{state}</span>

    </div>
  );
};

export default CreateItem;
