import { Stack } from "@fluentui/react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getItemDetail, getZoneDetail } from "../services/assetServices";
import BackButton from "../common/BackButton";

const ShowQR = () => {
  const query = new URLSearchParams(useLocation().search);
  const itemId = query.get("itemId");
  const zoneId = query.get("zoneId");

  const getImageData = (abortController: AbortController): Promise<string> => {
    if (itemId) {
      return getItemDetail(abortController, itemId || "")
        .then((data) => {
          return `data:image/png;base64,${data.qrCode.data}`;
        })
    } else if (zoneId) {
      return getZoneDetail(abortController, zoneId || "")
        .then((data) => {
          return `data:image/png;base64,${data.qrCode.data}`;
        })
    }
    throw new Error();
  }

  useEffect(() => {
    const abortController = new AbortController();
    setState("Downloading...");
    getImageData(abortController)
      .then((img: string) => {
        setImage(img);
        setState("");
      })
      .catch((error) => {
        console.error("Error:", error);
        setState(error.message);
      });

    return () => {
      abortController.abort();
    }
  }, [itemId]);

  const [image, setImage] = useState("");
  const [state, setState] = useState("");

  return (
    <div>
      <Stack>
        <BackButton backTo={`${itemId ? '/' : '/zones'}`} />
        {state !== "" ? <span>{state}</span> : ""}
        <div className="ms-Grid">
          <div className="ms-Grid-row">
            <img src={image} alt="" />
          </div>
          <div className="ms-Grid-row">
            <span>{itemId ? "Asset" : "Zone"} Id: {itemId ?? zoneId}</span>
          </div>
        </div>
      </Stack>
    </div>
  );
};

export default ShowQR;
