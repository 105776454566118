import {
  CommandBar,
  ICommandBarItemProps,
  IconButton,
  Link,
  SearchBox,
  Stack,
  Text,
} from "@fluentui/react";
import {
  DetailsListLayoutMode,
  IColumn,
  SelectionMode,
} from "@fluentui/react/lib/DetailsList";
import { Selection } from "@fluentui/react/lib/DetailsList";
import { ShimmeredDetailsList } from "@fluentui/react/lib/ShimmeredDetailsList";
import { ITextField, TextField } from "@fluentui/react/lib/TextField";
import { useContext, useEffect, useRef, useState } from "react";
import AppContext from "./AppContext";
import { getItems, IAsset, IPagedCollection } from "../services/assetServices";
import { useNavigate } from "react-router-dom";
import { scrollStackItemStyles } from "../common/styles/StackStyles";
import { appRoles } from "../authConfig";

export interface IListItem {
  id: number;
  uuid: string;
  code: string;
  note: string;
}
export interface IListState {
  items: IListItem[];
  selectionDetails: string;
}

const ItemsList = () => {
  let navigate = useNavigate();

  const [allItems, setData] = useState<IAsset[]>([]);
  const [isLastPage, setIsLastPage] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState({ no: 1, search: "" });
  const [shimmered, setShimmered] = useState(false);
  const context = useContext(AppContext);

  useEffect(() => {
    const abortController = new AbortController();
    context.setIsInProgress(true);
    getItems(abortController, 10, page.no, page.search)
      .then((data: IPagedCollection<IAsset>) => {
        setData(data.items.map(asset => {
          const newAsset: IAsset = {
            ...asset,
            changeDate: new Date(asset.changeDate + 'Z').toLocaleString()
          };
          return newAsset;
        }))
        setIsLastPage(data.isLastPage);
        setPageCount(data.pageCount);
        setShimmered(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        context.setErrorMessage(error.message);
      })
      .finally(() => {
        context.setIsInProgress(false);
      });
    return () => {
      abortController.abort();
    }
  }, [page.no, page.search]);

  const _items: ICommandBarItemProps[] = [
    {
      key: "newItem",
      text: "New",
      cacheKey: "myCacheKey", // changing this key will invalidate this item's cache
      iconProps: { iconName: "Add" },
      onClick: () => navigate("/newItem"),
      disabled: !context.profileData.roles.includes(appRoles.Admin)
    },
    {
      key: "showQR",
      text: "Show QR",
      iconProps: { iconName: "QRCode" },
      onClick: () => {
        context.setSelectedItem({ id: 0, uuid: "" });
        navigate(`/showQR?itemId=${context.selectedItem.id}`);
      },
      disabled: context.selectedItem.uuid === "",
    },
    {
      key: "assetDetail",
      text: "Detail",
      iconProps: { iconName: "DocumentSet" },
      onClick: () => {
        context.setSelectedItem({ id: 0, uuid: "" });
        navigate(`/assetDetail?itemId=${context.selectedItem.id}`);
      },
      disabled: context.selectedItem.uuid === "",
    },
    {
      key: "assetEdit",
      text: "Edit",
      iconProps: { iconName: "Edit" },
      onClick: () => {
        context.setSelectedItem({ id: 0, uuid: "" });
        navigate(`/editItem?itemId=${context.selectedItem.id}`);
      },
      disabled: !context.profileData.roles.includes(appRoles.Admin) || context.selectedItem.uuid === "",
    },
    {
      key: "assetDelete",
      text: "Delete",
      iconProps: { iconName: "Delete" },
      onClick: () => {
        context.setSelectedItem({ id: 0, uuid: "" });
        navigate(`/assetDetail?action=delete&itemId=${context.selectedItem.id}`);
      },
      disabled: !context.profileData.roles.includes(appRoles.Admin) || context.selectedItem.uuid === "",
    },
  ];

  const _columns: IColumn[] = [
    {
      key: "Id",
      name: "Id",
      fieldName: "id",
      minWidth: 50,
      maxWidth: 50,
      isResizable: true,
      onRender: item => (
        <Link key={item.assetId} onClick={() => navigate(`/assetDetail?itemId=${item.id}`)}>
          {item.id}
        </Link >
      )
    },
    {
      key: "Code",
      name: "Code",
      fieldName: "code",
      minWidth: 100,
      maxWidth: 125,
      isResizable: true,
    },
    {
      key: "category",
      name: "Category",
      fieldName: "category",
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
    },
    {
      key: "AssetDate",
      name: "Asset Date",
      fieldName: "changeDate",
      minWidth: 150,
      maxWidth: 175,
      isResizable: true,
    },
    {
      key: "Note",
      name: "Description",
      fieldName: "note",
      minWidth: 250,
      maxWidth: 250,
      isResizable: true,
    },
    {
      key: "zoneName",
      name: "Zone",
      fieldName: "zoneName",
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
    },
  ];

  const [uuid, setUuid] = useState("");
  const _selection = new Selection({
    onSelectionChanged: () => {
      const sel = _getSelectionDetails();
      // console.log(sel);
      setUuid(sel.message);
      if (sel.item) {
        context.setSelectedItem(sel.item);
      }
    },
  });

  const _getSelectionDetails = () => {
    const selectionCount = _selection.getSelectedCount();

    switch (selectionCount) {
      case 0:
        return { count: 0, message: "No items selected" };
      case 1: {
        const firstSelection = _selection.getSelection()[0] as IListItem;
        return {
          count: 1,
          id: firstSelection.id,
          item: firstSelection,
          message: firstSelection.uuid,
        };
      }
      default:
        return {
          count: selectionCount,
          message: `${selectionCount} items selected`,
        };
    }
  };

  return (
    <div>
      <Stack horizontal verticalAlign="center">
        <CommandBar
          items={_items}
          ariaLabel="Items actions"
          primaryGroupAriaLabel="Items actions"
          farItemsGroupAriaLabel="More actions"
        />
        <SearchBox
          placeholder="Search asset"
          onSearch={(newValue) => setPage({ no: 1, search: newValue || "" })}
          onClear={() => setPage({ no: 1, search: "" })} />
      </Stack>
      <Stack>
        <Stack.Item align="center" styles={scrollStackItemStyles} >
          <ShimmeredDetailsList
            items={allItems}
            columns={_columns}
            setKey="set"
            layoutMode={DetailsListLayoutMode.justified}
            selection={_selection}
            selectionMode={SelectionMode.single}
            selectionPreservedOnEmptyClick={true}
            ariaLabelForSelectionColumn="Toggle selection"
            ariaLabelForSelectAllCheckbox="Toggle selection for all items"
            checkButtonAriaLabel="select row"
            enableShimmer={shimmered}
          />
        </Stack.Item>
      </Stack>
      <Stack horizontal horizontalAlign="space-between">
        <Stack.Item grow={1} align="center">
          <IconButton
            iconProps={{ iconName: "DoubleChevronLeft" }}
            disabled={page.no === 1}
            onClick={() => setPage({ no: 1, search: page.search })}
          />
          <IconButton
            iconProps={{ iconName: "ChevronLeft" }}
            disabled={page.no === 1}
            onClick={() => setPage({ no: page.no - 1, search: page.search })}
          />
          <Text>
            {page.no} of {pageCount}
          </Text>
          <IconButton
            iconProps={{ iconName: "ChevronRight" }}
            disabled={isLastPage}
            onClick={() => setPage({ no: page.no + 1, search: page.search })}
          />
          <IconButton
            iconProps={{ iconName: "DoubleChevronRight" }}
            disabled={isLastPage}
            onClick={() => setPage({ no: pageCount, search: page.search })}
          />
        </Stack.Item>
      </Stack>
      <Stack horizontalAlign="center">
        <TextField label="UUID" styles={{ root: { width: "400px" } }} readOnly value={uuid} />
      </Stack>
    </div>
  );
};

export default ItemsList;
