import { getAuthHeader } from "./authHelper";

export interface IAssetDetails {
  asset: IAsset;
  issues: IIssue[];
  qrCode: IQrCode;
}

export interface supportedIssue {
  name: string;
  id: string;
}
export interface IAsset {
  changeDate?: string;
  code: string;
  createdAt?: string;
  id: number;
  note: string;
  uuid: string;
  category: string;
  categoryId: number;
  supportedIssues?: supportedIssue[]
}

export interface IQrCode {
  assetId: number;
  createdAt?: string;
  data: string;
  id: number;
}

export interface IImportedAsset {
  code: string;
  id: number;
  ChangedDate: Date;
}

export interface IImportResult {
  importedItems: IImportedAsset[];
  logFilePath: string;
}

export interface IIssue {
  id: number;
  comment: string;
  imageUrl: string;
  image: string;
  workOrdersCount: number;
  createdAt: string;
}
export interface IContractor {
  id: number;
  name: string;
  workOrders: any;
  createdAt: string;
}
export interface IWorkOrder {
  id: number;
  issueId: number;
  code: string;
  description: string;
  status: string;
  statusId: number;
  statusDescription: string;
  createdAt: string;
}
export interface IZone {
  id?: number;
  code?: string;
  name?: string;
  childZones?: IZone[],
  parentId?: number;
  assetCategories?: IAssetCategory[]
}

export interface ICreateZoneRequest {
  code: string;
  name: string;
  parentId?: number;
  assetCategories?: number[];
}

export interface ICreateCategoryRequest {
  code: string;
  name: string;
  parentId?: number;
}

export interface IPagedCollection<itemType> {
  isLastPage: boolean;
  items: itemType[];
  pageCount: number;
  pageNo: number;
  pageSize: number;
  rowsCount: number;
}

export interface IImage {
  imageContent: string;
}

export interface ICreateAssetRequest {
  uuid: string;
  code: string;
  note: string;
  categoryId: number;
  changedate: string;
}

export interface IUpdateAssetRequest {
  code: string | undefined;
  note: string | undefined;
  categoryId: number | undefined;
  changedate: string | undefined;
}
export interface IUpdateZoneRequest {
  code: string | undefined;
  name: string | undefined;
  parentId: number | undefined;
  assetCategories?: number[]
}
export interface IUpdateIssueRequest {
  assetId: number | undefined;
}
export interface IUpdateCategoryRequest {
  code: string | undefined;
  name: string | undefined;
  parentId: number | undefined;
}
export interface ICreateAssetResponse {
  assetId: number;
  qrCodeId: number;
  created: boolean;
}
export interface ICreateContractorRequest {
  name: string;
  code: string;
  workOrdersIds: number[];
  otherInfo: string;
  assignmentsDescription: string;
}
export interface ICreateZoneResponse {
  zoneId: number;
}
export interface ICreateContractorResponse {
  contractorId: number;
}
export interface ICreateCategoryResponse {
  assetCategoryId: number;
}
export interface IResendWorkOrderResponse {
  workOrderId: number;
}
export interface ILastImport {
  lastImport: { lastImportedDateTime: string; lastImportedId: string, createdAt: string };
  latestImportedAsset: { code: string, changeDate: string };
  latestAssetInSourceDate: string;
  latestAssetInSourceNum: string;
}

export interface IAssetCategories2IssueTypesRequest {
  csvContent: string;
}

export interface IImportZoneRequest {
  csvContent: string;
}
export interface IAssetCategories2IssueTypesResponse {
  isSuccess: Boolean;
  errors: string[];
}

export interface IImportZonesResponse {
  isSuccess: Boolean;
  errors: string[];
  importedZones: string[];
  logFilePath: string;
}

export interface ISettings {
  clientUrl: string;
}

export interface IWorkOrderStatuses {
  id: number;
  name: string;
  code: string;
  color: string;
  icon: string;
  }

export interface IIsueType {
  id: number;
  code: string;
  name: string;
  priority: string;
}

export interface IAssetCategory {
  id?: number;
  code: string;
  name: string;
  subCategories?: IAssetCategory[],
  parentId?: number;
  issueTypes?: IIsueType[];
}

const internalFetch = async (
  url: string,
  method: "GET" | "POST" | "PUT" | "DELETE",
  abortController: AbortController,
  body?: string,
  noJsonResponse?: boolean
): Promise<any> => {
  const headers = await getAuthHeader();

  const options = {
    method: method,
    headers: headers,
    timeout: 3000,
    body: body,
    signal: abortController?.signal
  };

  try {
    const response = await fetch(url, options);
    if (response.ok) {
      return Promise.resolve(response).then(
        (response) => (noJsonResponse ? response : response.json()),
        (reason: any) => {
          const errorMessage: string = reason.errorMessage || "";
          if (errorMessage.includes("AADSTS50058")) {
            throw new Error("Authentication token expired, please sign in again");
          }
        }
      );
    } else {
      return Promise.reject(response);
    }
  } catch (error) {
    console.log(error);
    return Promise.reject(error);
  }
};

export const getItems = async (
  abortController: AbortController,
  pageSize: number,
  pageNo: number,
  search?: string
): Promise<IPagedCollection<IAsset>> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL
    }/asset?pageSize=${pageSize}&pageNo=${pageNo}${search ? `&search=${search}` : ""
    }&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "GET",
    abortController
  );
};

export const getIssues = async (
  abortController: AbortController,
  pageSize: number,
  pageNo: number,
  search?: string,
  filters?: string,
): Promise<IPagedCollection<IIssue>> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL
    }/issues?pageSize=${pageSize}&pageNo=${pageNo}${search ? `&search=${search}` : ""
    }${filters ? `&filters=${filters}` : ""
    }&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "GET",
    abortController
  );
};

export const editIssue = async (
  abortController: AbortController,
  issueId: number,
  issue: IUpdateIssueRequest
): Promise<number> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/issueById?issueId=${issueId}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "PUT",
    abortController,
    JSON.stringify(issue),
  )
}

export const getContractors = async (
  abortController: AbortController,
  pageSize: number,
  pageNo: number,
  search?: string
): Promise<IPagedCollection<IContractor>> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL
    }/contractors?pageSize=${pageSize}&pageNo=${pageNo}${search ? `&search=${search}` : ""
    }&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "GET",
    abortController
  );
};

export const getZones = async (
  abortController: AbortController,
  search?: string
): Promise<IZone[]> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL
    }/zones?${search ? `&search=${search}` : ""
    }&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "GET",
    abortController
  );
};

export const editZone = async (
  abortController: AbortController,
  zoneId: string,
  zone: IUpdateZoneRequest
): Promise<number> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/zoneById?zoneId=${zoneId}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "PUT",
    abortController,
    JSON.stringify(zone),
  )
}

export const createZone = async (
  abortController: AbortController,
  zone: ICreateZoneRequest
): Promise<ICreateZoneResponse> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/zone`,
    "POST",
    abortController,
    JSON.stringify(zone),
  )
}

export const deleteZone = async (
  abortController: AbortController,
  zoneId: string,
): Promise<number> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/zoneById?zoneId=${zoneId}`,
    "DELETE",
    abortController,
  )
}

export const editCategory = async (
  abortController: AbortController,
  categoryId: string,
  category: IUpdateCategoryRequest
): Promise<number> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/assetCategory?assetCategoryId=${categoryId}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "PUT",
    abortController,
    JSON.stringify(category),
  )
}

export const createCategory = async (
  abortController: AbortController,
  category: ICreateCategoryRequest
): Promise<ICreateCategoryResponse> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/assetCategory`,
    "POST",
    abortController,
    JSON.stringify(category),
  )
}
export const getItemDetail = async (
  abortController: AbortController,
  assetId: string
): Promise<IAssetDetails> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/assetById?assetId=${assetId}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "GET",
    abortController
  );
};

export const getZoneDetail = async (
  abortController: AbortController,
  zoneId: string
): Promise<IAssetDetails> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/zoneById?zoneId=${zoneId}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "GET",
    abortController
  );
};

export const editAsset = async (
  abortController: AbortController,
  assetId: string,
  asset: IUpdateAssetRequest
): Promise<number> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/assetById?assetId=${assetId}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "PUT",
    abortController,
    JSON.stringify(asset),
  )
}
export const deleteAsset = async (
  abortController: AbortController,
  assetId: string
): Promise<number> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/assetById?assetId=${assetId}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "DELETE",
    abortController
  );
};

export const getImportAssets = async (
  abortController: AbortController,
): Promise<ILastImport> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/importassets`,
    "GET",
    abortController
  );
};

export const createAsset = async (
  abortController: AbortController,
  data: ICreateAssetRequest
): Promise<ICreateAssetResponse> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/asset`,
    "POST",
    abortController,
    JSON.stringify(data)
  )
}

export const createContractor = async (
  abortController: AbortController,
  data: ICreateContractorRequest
): Promise<ICreateContractorResponse> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/contractor`,
    "POST",
    abortController,
    JSON.stringify(data)
  )
}

export const getAssetCategories = async (
  abortController: AbortController,
): Promise<IAssetCategory[]> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/assetCategories`,
    "GET",
    abortController
  )
}

export const getWorkOrderStatuses = async (
  abortController: AbortController,
): Promise<IWorkOrderStatuses[]> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/getWorkOrderStatuses`,
    "GET",
    abortController
  )
}

export const importAssets = async (
  abortController: AbortController,
  clearChoice: boolean,
  seed: number
): Promise<IImportResult> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/importAssets`,
    "POST",
    abortController,
    JSON.stringify({
      reset: clearChoice,
      seed: seed,
    })
  );
};

export const previewImportAssets = async (
  abortController: AbortController,
): Promise<IImportedAsset[]> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/importAssetsPreview`,
    "GET",
    abortController
  );
};

export const getImage = async (
  abortController: AbortController,
  imageUrl: string): Promise<IImage> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/image?imageUrl=${imageUrl}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "GET",
    abortController
  );
};

export const getWorkOrders = async (
  abortController: AbortController,
  pageSize: number, pageNo: number): Promise<IPagedCollection<IWorkOrder>> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/workOrders?pageSize=${pageSize}&pageNo=${pageNo}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "GET",
    abortController
  );
};

export const searchWorkOrders = async (
  abortController: AbortController,
  search: string): Promise<IWorkOrder[]> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/searchWorkOrders?search=${search}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "GET",
    abortController
  );
};

export const getWorkOrdersOfIssue = async (
  abortController: AbortController,
  issueId: number): Promise<IWorkOrder[]> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/workOrdersOfIssue?issueId=${issueId}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "GET",
    abortController
  );
};

export const updateWorkOrderStatus = async (
  abortController: AbortController,
  workOrderCode: string,
  workOrderStatus: string,
  workOrderStatusId: number,
  workOrderDescription: string
) => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/workOrder?workOrderCode=${workOrderCode}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "PUT",
    abortController,
    JSON.stringify({
      status: workOrderStatus,
      statusId: workOrderStatusId,
      description: workOrderDescription,
    })
  );
};

export const resendWorkOrder = async (
  abortController: AbortController,
  workOrderId: number): Promise<IResendWorkOrderResponse> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/resendWorkOrder?workOrderId=${workOrderId}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "POST",
    abortController
  );
};

export enum QrCodeEntityTypes {
  Asset,
  Zone,
}

export const getQrCodes = async (
  abortController: AbortController,
  entity: QrCodeEntityTypes,
  pageSize: number,
  pageNo: number
): Promise<IPagedCollection<IQrCode>> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/qrcodes/${entity}/?pageSize=${pageSize}&pageNo=${pageNo}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "GET",
    abortController
  );
};

export const getQrCodesDoc = async (
  abortController: AbortController,
  entity: QrCodeEntityTypes,
): Promise<Response> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/qrcodesdoc/${entity}`,
    "GET",
    abortController,
    undefined,
    true
  );
};

export const getQrCodesPng = async (
  abortController: AbortController,
  entity: QrCodeEntityTypes,
): Promise<Response> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/qrcodespng/${entity}`,
    "GET",
    abortController,
    undefined,
    true
  );
};

export const getLogFile = async (
  abortController: AbortController,
  path: string,
): Promise<Response> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/file?filePath=${path}`,
    "GET",
    abortController,
    undefined,
    true
  );
};

export const uploadAssetCategories2IssueTypes = async (
  abortController: AbortController,
  request: IAssetCategories2IssueTypesRequest,
): Promise<IAssetCategories2IssueTypesResponse> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/importAssetCategories2IssueTypes`,
    "POST",
    abortController,
    JSON.stringify(request)
  );
};

export const uploadZones = async (
  abortController: AbortController,
  request: IImportZoneRequest,
): Promise<IImportZonesResponse> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/importZones`,
    "POST",
    abortController,
    JSON.stringify(request)
  );
};

export const getAssetCategoryIssueTypes = async (
  abortController: AbortController,
  assetCategoryId: string
): Promise<IIsueType[]> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/assetCategoryIssueTypes?assetCategoryId=${assetCategoryId}&code=${process.env.REACT_APP_BACKEND_API_KEY}`,
    "GET",
    abortController
  );
};

export const getSettings = async (
  abortController: AbortController,
): Promise<ISettings> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/config`,
    "GET",
    abortController
  );
};

export const getBuildNumber = async (
  abortController: AbortController,
): Promise<string> => {
  return internalFetch(
    `${process.env.REACT_APP_BACKEND_API_URL}/buildNumber`,
    "GET",
    abortController
  );
};