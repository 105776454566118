import "./CreateItem.css";
import { useContext, useEffect, useState } from "react";
import {
  Dropdown,
  SearchBox,
  IDropdownOption,
} from "@fluentui/react";
import { Stack } from "@fluentui/react/lib/Stack";
import AppContext from "./AppContext";
import { getZones, IZone } from "../services/assetServices";
import BackButton from "../common/BackButton";

const CreateIssue = () => {
  const [zones, setZones] = useState<IZone[]>();
  const [selectedZone, setSelectedZone] = useState<Number>();
  const [filteredZones, setFilteredZones] = useState<IZone[]>();

  const context = useContext(AppContext);

  useEffect(() => {
    const abortController = new AbortController();
    context.setIsInProgress(true);
    getZones(abortController)
      .then((data: IZone[]) => {
        setZones(data);
        setFilteredZones(data);
      })
      .catch((error) => {
        console.error("Error:", error);
        context.setErrorMessage(error.message);
      })
      .finally(() => {
        context.setIsInProgress(false);
      });
    return () => {
      abortController.abort();
    }
  }, []);

  return (
    <div>
      <Stack horizontal style={{ width: '450px' }}>
        <BackButton backTo="/issues" />
        <SearchBox
          placeholder="Search zone"
          onSearch={(newValue) => setFilteredZones(zones?.filter(zone => zone.name?.includes(newValue)))}
          onClear={() => setFilteredZones(zones)}
          disableAnimation
          styles={{ root: { width: '180px' } }}
        />
        <Dropdown
          options={filteredZones ? filteredZones?.map(z => ({ key: z.id ?? 0, text: z.name ?? "" })) : []}
          onChange={(ev, selectedOption?: IDropdownOption) => {
            setSelectedZone(Number.parseInt((selectedOption?.key ?? "").toString()))
          }}
          styles={{ root: { width: '180px' } }}
        />
      </Stack>
      {selectedZone ?
        <iframe
          title="Create issue"
          src={`${process.env.REACT_APP_CLIENT_URL}/?zone=${selectedZone}&emulationMode=true`}
          style={{ width: '450px', height: '800px' }}
        /> :
        <></>}
    </div>
  );
};

export default CreateIssue;
