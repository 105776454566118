import { useContext, useEffect, useRef, useState } from "react";
import {
  DefaultButton,
  PrimaryButton,
  Dropdown,
} from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { TextField } from "@fluentui/react/lib/TextField";
import { Stack, IStackProps, IStackStyles } from "@fluentui/react/lib/Stack";
import { useLocation, useNavigate } from "react-router-dom";
import AppContext from "./AppContext";
import { editAsset, getAssetCategories, getItemDetail, IAsset, IAssetCategory, IAssetDetails, IUpdateAssetRequest } from "../services/assetServices";
import ConfirmDialog from "../common/ConfirmDialog";

const EditItem = () => {
  const query = new URLSearchParams(useLocation().search);
  const itemId = query.get("itemId");

  const [state, setState] = useState("");
  const navigate = useNavigate();

  const [categories, setCategories] = useState<IAssetCategory[]>();
  const [asset, setAsset] = useState<IAsset>();

  const savedAsset = useRef<IAsset>();

  useEffect(() => {
    const abortController = new AbortController();
    context.setIsInProgress(true);
    getAssetCategories(abortController)
      .then((data: IAssetCategory[]) => {
        setCategories(data);
      })
      .then(() => {
        getItemDetail(abortController, itemId || "")
          .then((data: IAssetDetails) => {
            setAsset({
              code: data.asset.code,
              id: data.asset.id,
              note: data.asset.note,
              uuid: data.asset.uuid,
              category: '',
              categoryId: data.asset.categoryId,
            });
            savedAsset.current = { ...data.asset };
            setState("");
          })
          .catch((error) => {
            console.error("Error:", error);
            setState(error.message);
          });
      })
      .catch((error) => {
        console.error("Error:", error);
        context.setErrorMessage(error.message);
      })
      .finally(() => {
        context.setIsInProgress(false);
      });
    return () => {
      abortController.abort();
    }
  }, []);

  const onSubmit = (event: any) => {
    if (event) {
      event.preventDefault();
    }
    setState("Saving the item...");
    const abortController = new AbortController();
    const updateAssetRequest: IUpdateAssetRequest = {
      code: asset?.code,
      note: asset?.note,
      categoryId: Number(asset?.categoryId),
      changedate: asset?.changeDate
    };
    editAsset(abortController, itemId ?? '', updateAssetRequest)
      .then((data: number) => {
        setState("Item successfully updated");
        savedAsset.current = {
          id: data,
          uuid: savedAsset.current?.uuid ?? '',
          category: savedAsset.current?.category ?? '',
          code: updateAssetRequest.code ?? '',
          note: updateAssetRequest.note ?? '',
          categoryId: Number(updateAssetRequest.categoryId) ?? ''
        };
      })
      .catch((error) => {
        console.error("Error:", error);
        setState(`Error: ${error}`);
      });
  };

  const stackTokens = { childrenGap: 50 };
  const stackStyles: Partial<IStackStyles> = { root: { width: 650 } };
  const columnProps: Partial<IStackProps> = {
    tokens: { childrenGap: 15 },
    styles: { root: { width: 300 } },
  };

  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] =
    useBoolean(false);

  const context = useContext(AppContext);

  const handleChange = (e: any) => {
    switch (e.target.name) {
      case 'code':
        setAsset((prevItem: any) => ({ ...prevItem, code: e.target.value }));
        break;
      case 'note':
        setAsset((prevItem: any) => ({ ...prevItem, note: e.target.value }));
        break;
      default:
        break;
    }
  };

  return (
    <div className="App">
      <form onSubmit={onSubmit} >
        <div>
          <div className="form-group">
            <Stack horizontal tokens={stackTokens} styles={stackStyles}>
              <Stack {...columnProps}>
                <TextField
                  label="Code"
                  type="text"
                  value={asset?.code}
                  name="code"
                  onChange={handleChange}
                />
                <TextField
                  label="Note"
                  type="text"
                  name="note"
                  value={asset?.note}
                  onChange={handleChange}
                />
                <Dropdown
                  options={categories?.map(cat => ({ key: cat.id ?? '', text: cat.name })) ?? []}
                  selectedKey={asset?.categoryId}
                  onChange={(e, o) => {
                    setAsset((prevItem: any) => ({ ...prevItem, categoryId: o?.key }));
                  }}
                />
                <Stack.Item align="center">
                  <Stack horizontal tokens={stackTokens}>
                    <PrimaryButton type="submit">Save</PrimaryButton>
                    <DefaultButton
                      onClick={() => {
                        if (savedAsset.current?.code !== asset?.code ||
                          savedAsset.current?.note !== asset?.note ||
                          savedAsset.current?.categoryId !== asset?.categoryId) {
                          showModal();
                        } else {
                          navigate("/");
                        }
                      }}
                    >
                      Cancel
                    </DefaultButton>
                  </Stack>
                </Stack.Item>
              </Stack>
            </Stack>

            <ConfirmDialog isModalOpen={isModalOpen} hideModal={hideModal}
              message="All your unsaved changes would be lost."
              onYesClick={() => navigate("/")} />

          </div>
        </div>
      </form>{" "}
      <span>{state}</span>
    </div>
  );
};


export default EditItem;
