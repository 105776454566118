import {
  CommandBar,
  DetailsListLayoutMode,
  IColumn,
  ICommandBarItemProps,
  IconButton,
  ITextField,
  mergeStyleSets,
  SelectionMode,
  ShimmeredDetailsList,
  Stack,
  Text,
  TooltipHost,
} from "@fluentui/react";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { appRoles } from "../authConfig";
import { scrollStackItemStyles } from "../common/styles/StackStyles";
import { getContractors, IContractor, IPagedCollection } from "../services/assetServices";
import AppContext from "./AppContext";

function Contractors() {
  const [contractors, setContractors] = useState<IContractor[]>([]);
  const [isLastPage, setIsLastPage] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState({ no: 1, search: "" });
  const [shimmered, setShimmered] = useState(false);

  const searchTextField = useRef<ITextField>(null);

  const navigate = useNavigate();

  const context = useContext(AppContext);
  useEffect(() => {
    context.setIsInProgress(true);
    const abortController = new AbortController();

    getContractors(abortController, 10, page.no, page.search)
      .then((data: IPagedCollection<IContractor>) => {
        setContractors(data.items.map(contractor => {
          const newIssue: IContractor = {
            ...contractor,
            createdAt: new Date(contractor.createdAt +'Z').toLocaleString()
          };
          return newIssue;
        }))
        setIsLastPage(data.isLastPage);
        setPageCount(data.pageCount);
        setShimmered(false);
      })
      .catch((error: { message: string }) => {
        console.error("Error:", error);
        context.setErrorMessage(error.message);
      })
      .finally(() => {
        context.setIsInProgress(false);
      });

    return () => {
      abortController.abort();
    }
  }, [page.no, page.search]);

  const _columns: IColumn[] = [
    {
      key: "Id",
      name: "Id",
      fieldName: "id",
      minWidth: 25,
      maxWidth: 50,
      isResizable: true,
    },
    {
      key: "name",
      name: "Name",
      fieldName: "name",
      minWidth: 250,
      maxWidth: 250,
      isResizable: true,
    },
    {
      key: "code",
      name: "Code",
      fieldName: "code",
      minWidth: 70,
      maxWidth: 100,
      isResizable: true,
    },
    {
      key: "workOrders",
      name: "WorkOrders",
      fieldName: "workOrders",
      minWidth: 70,
      maxWidth: 100,
      isResizable: true,
    },
    {
      key: "createdAt",
      name: "Created at",
      fieldName: "createdAt",
      minWidth: 125,
      maxWidth: 155,
      isResizable: true,
    },
  ];

  const workOrderCellClass = mergeStyleSets({
    default: {
      padding: '5px',
    },
    bar: {
      height: '24px', display: 'block',
    }
  });

  const _renderItemColumn = (
    item: IContractor,
    index?: number,
    column?: IColumn
  ) => {
    const fieldContent = item[column?.fieldName as keyof IContractor] as string;

    switch (column?.key) {
      case "workOrders":
        return item.workOrders ? (
          // <span>keys: {Object.keys(item.workOrders)}, 
          // values: {Object.keys(item.workOrders).map(function (key) { return item.workOrders[key]; })}</span>
          <Stack className={workOrderCellClass.default} horizontal>
            {
              Object.keys(item.workOrders).map((key: string) => (
                // <div style={{ width: '40%', display: 'block' }}>
                <div style={{
                  width: `${100 * item.workOrders[key] /
                    (Object.values(item.workOrders).map((a: any) => a ?? 0).reduce((ps, a) => ps + a, 0))}%`,
                  display: 'block'
                }}>
                  <TooltipHost content={`${key}:${item.workOrders[key]}`} >
                    <span
                      className={workOrderCellClass.bar}
                      style={{ backgroundColor: `${key.toString() === 'WAPPR' ? 'lightpink' : 'lightgreen'}` }}
                    ></span>
                  </TooltipHost>
                </div>
              ))}
          </Stack>
        ) : (
          <span></span>
        );
      default:
        return <span>{fieldContent}</span>;
    }
  };
  const _items: ICommandBarItemProps[] = [
    {
      key: "newContractor",
      text: "New",
      iconProps: { iconName: "Add" },
      disabled: !context.profileData.roles.includes(appRoles.Admin),
      onClick: () => {
        navigate('/newContractor');
      },
    },
  ];

  return (
    <>
      <Stack>
      <CommandBar
            items={_items}
            ariaLabel="Items actions"
            primaryGroupAriaLabel="Items actions"
            farItemsGroupAriaLabel="More actions"
          />
        <Stack>
          <Stack.Item align="center" styles={scrollStackItemStyles}>
            <ShimmeredDetailsList
              items={contractors}
              columns={_columns}
              setKey="set"
              layoutMode={DetailsListLayoutMode.justified}
              onRenderItemColumn={_renderItemColumn}
              selectionMode={SelectionMode.single}
              selectionPreservedOnEmptyClick={true}
              ariaLabelForSelectionColumn="Toggle selection"
              ariaLabelForSelectAllCheckbox="Toggle selection for all items"
              checkButtonAriaLabel="select row"
              enableShimmer={shimmered}
            />
          </Stack.Item>
        </Stack>
        <Stack horizontal horizontalAlign="space-between">
          <Stack.Item grow={1} align="center">
            <IconButton
              iconProps={{ iconName: "DoubleChevronLeft" }}
              disabled={page.no === 1}
              onClick={() => setPage({ no: 1, search: page.search })}
            />
            <IconButton
              iconProps={{ iconName: "ChevronLeft" }}
              disabled={page.no === 1}
              onClick={() => setPage({ no: page.no - 1, search: page.search })}
            />
            <Text>
              {page.no} of {pageCount}
            </Text>
            <IconButton
              iconProps={{ iconName: "ChevronRight" }}
              disabled={isLastPage}
              onClick={() => setPage({ no: page.no + 1, search: page.search })}
            />
            <IconButton
              iconProps={{ iconName: "DoubleChevronRight" }}
              disabled={isLastPage}
              onClick={() => setPage({ no: pageCount, search: page.search })}
            />
          </Stack.Item>
        </Stack>
      </Stack>
    </>
  );
}

export default Contractors;
