import { Label, mergeStyleSets, Stack, TextField } from "@fluentui/react";
import { Dialog, DialogType } from "@fluentui/react/lib/Dialog";
import { useContext } from "react";

import AppContext from "./AppContext";

const Profile = () => {
  const dialogContentProps = {
    type: DialogType.normal,
    title: "Profile",
    closeButtonAriaLabel: "Close",
  };

  const context = useContext(AppContext);
  const styles = mergeStyleSets({
    labelColumn: {
      width: '100px'
    },
  });
  return (
    <>
      <Dialog
        hidden={!context.isProfileDialogOpen}
        onDismiss={() => context.setIsProfileDialogOpen(false)}
        dialogContentProps={dialogContentProps}
      >
        <Stack>
          <Stack horizontal>
            <Label className={styles.labelColumn}>First Name: </Label> 
            <TextField data-testid='firstNameText' readOnly value={context.profileData.givenName}></TextField>
          </Stack>
          <Stack horizontal>
            <Label className={styles.labelColumn}>Last Name: </Label> 
            <TextField data-testid='lastNameText' readOnly value={context.profileData.surname}></TextField>
          </Stack>
          <Stack horizontal>
            <Label className={styles.labelColumn}>Email: </Label> 
            <TextField data-testid='emailText' readOnly value={context.profileData.userPrincipalName}></TextField>
          </Stack>
          <Stack horizontal>
            <Label className={styles.labelColumn}>Id: </Label> 
            <TextField data-testid='idText' readOnly value={context.profileData.id}></TextField>
          </Stack>
        </Stack>
      </Dialog>
    </>
  );
};

export default Profile;
