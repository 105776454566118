import { CommandBar, DetailsListLayoutMode, IColumn, ICommandBarItemProps, mergeStyleSets, SelectionMode, ShimmeredDetailsList, Stack } from '@fluentui/react';
import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { appRoles } from '../authConfig';
import { scrollStackItemStyles } from '../common/styles/StackStyles';
import { getWorkOrderStatuses, IWorkOrderStatuses } from '../services/assetServices';
import AppContext from "./AppContext";

const WorkOrderStatuses = () => {
  const context = useContext(AppContext);
  const navigate = useNavigate();

  const [shimmered, setShimmered] = useState(false);
  const [workOrderStatuses, setWorkOrderStatuses] = useState<IWorkOrderStatuses[]>([]);

  useEffect(() => {
    context.setIsInProgress(true);
    const abortController = new AbortController();

    getWorkOrderStatuses(abortController)
      .then((data: IWorkOrderStatuses[]) => {
        setWorkOrderStatuses(data);
        setShimmered(false);
      })
      .catch((error: { message: string }) => {
        console.error("Error:", error);
        context.setErrorMessage(error.message);
      })
      .finally(() => {
        context.setIsInProgress(false);
      });

    return () => {
      abortController.abort();
    }
  }, []);

  const _columns: IColumn[] = [
    {
      key: "Id",
      name: "Id",
      fieldName: "id",
      minWidth: 25,
      maxWidth: 50,
      isResizable: true,
    },
    {
      key: "name",
      name: "Name",
      fieldName: "name",
      minWidth: 250,
      maxWidth: 250,
      isResizable: true,
    },
    {
      key: "code",
      name: "Code",
      fieldName: "code",
      minWidth: 70,
      maxWidth: 100,
      isResizable: true,
    },
    {
      key: "color",
      name: "Color",
      fieldName: "color",
      minWidth: 70,
      maxWidth: 100,
      isResizable: true,
    },
  ];

  const workOrderCellClass = mergeStyleSets({
    default: {
      padding: '5px',
    },
    bar: {
      height: '24px', display: 'block',
    }
  });

  const _renderItemColumn = (
    item: IWorkOrderStatuses,
    index?: number,
    column?: IColumn
  ) => {
    const fieldContent = item[column?.fieldName as keyof IWorkOrderStatuses] as string;

    switch (column?.key) {
      case "color":
        return item.color ? (
          <span
            className={workOrderCellClass.bar}
            style={{ backgroundColor: `${item.color}` }}
          ></span>

        ) : (
          <span></span>
        );
      default:
        return <span>{fieldContent}</span>;
    }
  };
  const _items: ICommandBarItemProps[] = [
    {
      key: "newWorkOrderStatuses",
      text: "New",
      iconProps: { iconName: "Add" },
      disabled: !context.profileData.roles.includes(appRoles.Admin),
      onClick: () => {
        navigate('/newWorkOrderStatuses');
      },
    },
  ];

  return (
    <>
      <Stack>
        <CommandBar
          items={_items}
          ariaLabel="Items actions"
          primaryGroupAriaLabel="Items actions"
          farItemsGroupAriaLabel="More actions"
        />
        <Stack>
          <Stack.Item align="center" styles={scrollStackItemStyles}>
            <ShimmeredDetailsList
              items={workOrderStatuses}
              columns={_columns}
              setKey="set"
              layoutMode={DetailsListLayoutMode.justified}
              onRenderItemColumn={_renderItemColumn}
              selectionMode={SelectionMode.single}
              selectionPreservedOnEmptyClick={true}
              ariaLabelForSelectionColumn="Toggle selection"
              ariaLabelForSelectAllCheckbox="Toggle selection for all items"
              checkButtonAriaLabel="select row"
              enableShimmer={shimmered}
            />
          </Stack.Item>
        </Stack>
      </Stack>
    </>
  );
}

export default WorkOrderStatuses