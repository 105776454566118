import { ITextField, Label, PrimaryButton, Stack, TextField } from '@fluentui/react'
import { useRef, useState } from 'react';
import { useContext } from 'react';
import AppContext from './components/AppContext';

export interface ILoginParams {
  onLoginSuccess: any;
}

export interface ITokenResponse {
  token: string;
}

const Login = (params: ILoginParams) => {

  const [isWrongPassword, setIsWrongPassword] = useState(false);
  const [isOtherServerError, setIsOtherServerError] = useState(false);

  const callLoginApi = async (username: string, password: string) => {
    const abortController: AbortController = new AbortController();

    const options = {
      method: "POST",
      timeout: 3000,
      body: JSON.stringify({ username: username, password: password }),
      signal: abortController?.signal
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_API_URL}/authenticate`, options);
      if (response.ok) {
        return Promise.resolve(response).then(
          (response) => (response.json()),
          (reason: any) => {
            const errorMessage: string = reason.errorMessage || "";
            throw new Error(errorMessage);
          }
        );
      } else {
        if (response.status === 401) {
          setIsWrongPassword(true);
          setIsOtherServerError(false);
        }
        return Promise.reject(response);
      }
    } catch (error) {
      setIsWrongPassword(false);
      setIsOtherServerError(true);
      console.log(error);
      return Promise.reject(error);
    }
  };

  const stackTokens = { childrenGap: 10 };

  const context = useContext(AppContext);

  const decodeJwt = (token: string): any => {
    var base64Payload = token.split(".")[1];
    var payloadBuffer = Buffer.from(base64Payload, "base64");
    return JSON.parse(payloadBuffer.toString());
  }

  const handleLoginClick = () => {
    callLoginApi(userNameInput.current?.value ?? '', passwordInput.current?.value ?? '')
      .then((tokenResponse: ITokenResponse) => {
        localStorage.setItem('token', tokenResponse.token);
        context.setIsAuthenticated(true);
        const decodedJtw = decodeJwt(tokenResponse.token);
        context.setProfileData({
          givenName: decodedJtw["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname"] ?? '',
          surname: decodedJtw["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname"] ?? '',
          userPrincipalName: decodedJtw["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"] ?? '',
          id: decodedJtw["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"] ?? '',
          roles: decodedJtw["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"] ?? ''
        });
        params.onLoginSuccess();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const userNameInput = useRef<ITextField>(null);
  const passwordInput = useRef<ITextField>(null);

  return (
    <Stack
      styles={{ root: { width: '500px', padding: '10px' } }}
      tokens={stackTokens}
      horizontalAlign="center">
      <Stack horizontal>
        <Label style={{ width: '75px' }}>User name</Label>
        <TextField componentRef={userNameInput} data-testid='userNameInput'></TextField>
      </Stack>
      <Stack horizontal>
        <Label style={{ width: '75px' }}>Password</Label>
        <TextField type='password' componentRef={passwordInput} data-testid='passwordInput'></TextField>
      </Stack>
      <PrimaryButton text='Login'
        data-testid='loginButton'
        onClick={handleLoginClick}></PrimaryButton>
      {isWrongPassword ?
        <Label data-testid='isWrongPasswordLabel'>Username or password is wrong.</Label> :
        <></>}
      {isOtherServerError ?
        <Label>Login failed (server error)</Label> :
        <></>}
    </Stack>
  )
}

export default Login