import React from "react";

export interface IDetailsListBasicExampleItem {
  id: number;
  uuid: string;
  code?: string;
  note?: string;
}

export interface IProfileData {
  givenName: string,
  surname: string,
  userPrincipalName: string,
  id: string,
  roles: string[],
}

const defaultItem: IDetailsListBasicExampleItem = {id: 0, uuid: '', code: '', note: ''};

export const defaultProfile: IProfileData = {
  givenName: "",
  surname: "",
  userPrincipalName: "",
  id: "",
  roles: [],
};

const AppContext = React.createContext({
  selectedItem: defaultItem,
  setSelectedItem: (item: IDetailsListBasicExampleItem) => {},
  isProfileDialogOpen: false,
  setIsProfileDialogOpen: (flag: boolean) => {},
  profileData: defaultProfile,
  setProfileData: (profileData: IProfileData) => {},
  isAuthenticated: false,
  setIsAuthenticated: (isAuthenticated: boolean) => {},
  errorMessage: "",
  setErrorMessage: (message: string) => {},
  isInProgress: false,
  setIsInProgress: (inProgress: boolean) => {},
});

export default AppContext;
