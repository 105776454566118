import { IStackStyles, ITextFieldStyles, Stack, TextField } from "@fluentui/react";
import { useEffect, useState } from "react";
import BackButton from "../common/BackButton";
import { getSettings, ISettings } from "../services/assetServices";

const Settings = () => {
  const [clientUrl, setClientUrl] = useState("");

  useEffect(() => {
    const abortController = new AbortController();

    getSettings(abortController).then((data: ISettings) => {
      setClientUrl(data.clientUrl);
    });
    
    return () => {
      abortController.abort();
    }
  }, []);

  const textFieldStyles: Partial<ITextFieldStyles> = {
    fieldGroup: { width: 300 },
    root: {padding: 10}
  };

  const stackStyles: IStackStyles = {
    root: {
      label: {
        display: 'flex',
        paddingLeft: 10
      }
    }
  }
  return (
    <Stack styles={stackStyles} horizontalAlign="start">
      <BackButton />
      <TextField
        label="ClientUrl"
        value={clientUrl}
        styles={textFieldStyles}
        readOnly
      />
    </Stack>
  );
};

export default Settings;
