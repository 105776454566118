import {
  CommandBar,
  DetailsListLayoutMode,
  IColumn,
  ICommandBarItemProps,
  IconButton,
  Link,
  mergeStyles,
  Modal,
  Panel,
  PanelType,
  SearchBox,
  SelectionMode,
  ShimmeredDetailsList,
  Stack,
  Text,
} from "@fluentui/react";
import { DetailsList, IDetailsColumnProps, Selection } from "@fluentui/react/lib/DetailsList";
import { useBoolean } from "@fluentui/react-hooks";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { appRoles } from "../authConfig";
import { scrollStackItemStyles } from "../common/styles/StackStyles";
import { editIssue, getImage, getIssues, getItems, IAsset, IImage, IIssue, IPagedCollection } from "../services/assetServices";
import AppContext from "./AppContext";
import HeaderWithFilter from "../common/HeaderWithFilter";

function Issues() {
  const [issues, setIssues] = useState<IIssue[]>([]);
  const [isLastPage, setIsLastPage] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState({ no: 1, search: "", filters: "" });
  const [shimmered, setShimmered] = useState(false);
  const [currentIssue, setCurrentIssue] = useState(0);
  const [selectedIssues, setSelectedIssues] = useState<IIssue[]>([]);
  const [assets, setAssets] = useState<IAsset[]>([]);

  const navigate = useNavigate();

  const context = useContext(AppContext);

  const [refreshCount, setRefreshCount] = useState(0);

  useEffect(() => {
    context.setIsInProgress(true);
    const abortController = new AbortController();

    getIssues(abortController, 10, page.no, page.search, page.filters)
      .then((data: IPagedCollection<IIssue>) => {
        setIssues(data.items.map(issue => {
          const newIssue: IIssue = {
            ...issue,
            createdAt: new Date(issue.createdAt + 'Z').toLocaleString()
          };
          return newIssue;
        }))
        setIsLastPage(data.isLastPage);
        setPageCount(data.pageCount);
        setShimmered(false);
      })
      .catch((error: { message: string }) => {
        console.error("Error:", error);
        context.setErrorMessage(error.message);
      })
      .finally(() => {
        context.setIsInProgress(false);
      });

    return () => {
      abortController.abort();
    }
  }, [page.no, page.search, page.filters, refreshCount]);

  const itemClass = mergeStyles({
    selectors: {
      "&:hover": {
        textDecoration: "underline",
        cursor: "pointer",
      },
    },
  });
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
  const [isAssetSelectorOpen, { setTrue: showAssetSelector, setFalse: hideAssetSelector }] = useBoolean(false);

  const handleClickOnImageLink = (issue: IIssue | undefined) => {
    if (!issue) {
      return;
    }
    const abortController = new AbortController();

    getImage(abortController, issue.imageUrl)
      .then((data: IImage) => {
        const index = issues.indexOf(issue);
        issues[index].image = data.imageContent;
        setIssues(issues);
        // setState("");
        setCurrentIssue(index);
        showModal();
      })
      .catch((error) => {
        console.error("Error:", error);
        // setState(error.message);
      });
  };

  const _renderItemColumn = (
    item: IIssue,
    index?: number,
    column?: IColumn
  ) => {
    const fieldContent = item[column?.fieldName as keyof IIssue] as string;

    switch (column?.key) {
      case "image":
        return item.imageUrl ? (
          <Link
            className={itemClass}
            disabled={item?.imageUrl === ""}
            onClick={() => handleClickOnImageLink(item)}
          >
            image
          </Link>
        ) : (
          <span></span>
        );
      default:
        return <span>{fieldContent}</span>;
    }
  };

  const _columns: IColumn[] = [
    {
      key: "Id",
      name: "Id",
      fieldName: "id",
      minWidth: 25,
      maxWidth: 50,
      isResizable: true,
    },
    {
      key: "uuid",
      name: "UUID",
      fieldName: "uuid",
      minWidth: 70,
      maxWidth: 100,
      isResizable: true,
    },
    {
      key: "Comment",
      name: "Comment",
      fieldName: "comment",
      minWidth: 250,
      maxWidth: 250,
      isResizable: true,
    },
    {
      key: "image",
      name: "Image",
      fieldName: "imageUrl",
      minWidth: 50,
      maxWidth: 50,
    },
    {
      key: "assetId",
      name: "Asset Id",
      fieldName: "assetId",
      minWidth: 70,
      maxWidth: 100,
      isResizable: true,
      onRender: item => (
        <Link key={item.assetId} onClick={() => navigate(`/assetDetail?itemId=${item.assetId}`)}>
          {item.assetId}
        </Link >
      )
    },
    {
      key: "createdAt",
      name: 'CreateAt',
      onRenderHeader: (props?: IDetailsColumnProps) => {
        return (
          <HeaderWithFilter
            columnName={props?.column.name ?? ""}
            columnDbName="CREATED_AT"
            type="D"
            onFilterSelected={(filterStr: string) => setPage({ ...page, filters: filterStr })}
            onFilterCleared={() => setPage({ ...page, filters: '' })}
          />
        );
      },
      fieldName: "createdAt",
      minWidth: 125,
      maxWidth: 155,
      isResizable: true,
    },
    {
      key: "crmCaseId",
      name: "CRM Case Id",
      fieldName: "crmCaseId",
      minWidth: 120,
      maxWidth: 170,
      isResizable: true,
    },
    {
      key: "crmCaseStatusId",
      name: "CRM Status",
      fieldName: "crmCaseStatusId",
      minWidth: 100,
      maxWidth: 120,
      isResizable: true,
    },
  ];
  const _items: ICommandBarItemProps[] = [
    {
      key: "newIssue",
      text: "New",
      iconProps: { iconName: "Add" },
      onClick: () => navigate("/newIssue"),
      disabled: !context.profileData.roles.includes(appRoles.Admin)
    },
    {
      key: "setIssueAsset",
      text: "Set Asset",
      iconProps: { iconName: "Link" },
      onClick: () => {
        showAssetSelector();
      },
      disabled: !context.profileData.roles.includes(appRoles.Admin) || selectedIssues.length === 0,
    },
    {
      key: "deleteIssue",
      text: "Delete",
      iconProps: { iconName: "Delete" },
      disabled: true,
      // onClick: () => {
      //   context.setSelectedItem({ id: 0, uuid: "" });
      //   navigate(`/assetDetail?action=delete&itemId=${context.selectedItem.id}`);
      // },
      // disabled: !context.profileData.roles.includes(appRoles.Admin) || context.selectedItem.uuid === "",
    },
  ];
  const _selection = new Selection({
    onSelectionChanged: () => {
      setSelectedIssues(_selection.getSelection().map(item => item as IIssue))
    },
  });

  return (
    <>
      <Stack>
        <Stack horizontal verticalAlign="center">
          <CommandBar
            items={_items}
            ariaLabel="Items actions"
            primaryGroupAriaLabel="Items actions"
            farItemsGroupAriaLabel="More actions"
          />
          <SearchBox
            placeholder="Search issue"
            onSearch={(newValue) => setPage({ ...page, no: 1, search: newValue || "" })}
            onClear={() => setPage({ ...page, no: 1, search: "" })} />
        </Stack>
        <Stack>
          <Stack.Item align="center" styles={scrollStackItemStyles}>
            <ShimmeredDetailsList
              items={issues}
              columns={_columns}
              setKey="set"
              layoutMode={DetailsListLayoutMode.justified}
              selection={_selection}
              onRenderItemColumn={_renderItemColumn}
              selectionMode={SelectionMode.single}
              selectionPreservedOnEmptyClick={true}
              ariaLabelForSelectionColumn="Toggle selection"
              ariaLabelForSelectAllCheckbox="Toggle selection for all items"
              checkButtonAriaLabel="select row"
              enableShimmer={shimmered}
            />
          </Stack.Item>
        </Stack>
        <Stack horizontal horizontalAlign="space-between">
          <Stack.Item grow={1} align="center">
            <IconButton
              iconProps={{ iconName: "DoubleChevronLeft" }}
              disabled={page.no === 1}
              onClick={() => setPage({ ...page, no: 1 })}
            />
            <IconButton
              iconProps={{ iconName: "ChevronLeft" }}
              disabled={page.no === 1}
              onClick={() => setPage({ ...page, no: page.no - 1 })}
            />
            <Text>
              {page.no} of {pageCount}
            </Text>
            <IconButton
              iconProps={{ iconName: "ChevronRight" }}
              disabled={isLastPage}
              onClick={() => setPage({ ...page, no: page.no + 1 })}
            />
            <IconButton
              iconProps={{ iconName: "DoubleChevronRight" }}
              disabled={isLastPage}
              onClick={() => setPage({ ...page, no: pageCount })}
            />
          </Stack.Item>
        </Stack>
      </Stack>
      <Modal isOpen={isModalOpen} onDismiss={hideModal} isBlocking={false}>
        <div>
          <img src={issues[currentIssue]?.image} alt="" />
        </div>
      </Modal>
      <Panel
        isLightDismiss
        isOpen={isAssetSelectorOpen}
        onDismiss={hideAssetSelector}
        closeButtonAriaLabel="Close"
        type={PanelType.medium}
        headerText="Pick asset for selected issues"
      >
        <SearchBox
          placeholder="Search asset"
          onSearch={(newValue) => {
            const abortController = new AbortController();
            getItems(abortController, 10, 1, newValue)
              .then((assets: IPagedCollection<IAsset>) => {
                setAssets(assets.items)
              })
          }}
          onClear={() => setAssets([])} />
        <DetailsList
          columns={[
            {
              key: "Id",
              name: "Id",
              fieldName: "id",
              minWidth: 50,
              maxWidth: 50,
              isResizable: true,
              onRender: item => (
                <Link key={item.assetId} onClick={() => {
                  const abortController = new AbortController();
                  const updatedIssue = { assetId: item?.id };
                  editIssue(abortController, selectedIssues[0].id, updatedIssue)
                    .then(() => {
                      hideAssetSelector();
                      setRefreshCount(refreshCount + 1);
                    });
                }}>
                  {item.id}
                </Link >
              )
            },
            {
              key: "Code",
              name: "Code",
              fieldName: "code",
              minWidth: 100,
              maxWidth: 125,
              isResizable: true,
            },
            {
              key: "category",
              name: "Category",
              fieldName: "category",
              minWidth: 100,
              maxWidth: 150,
              isResizable: true,
            },
            {
              key: "Note",
              name: "Description",
              fieldName: "note",
              minWidth: 250,
              maxWidth: 250,
              isResizable: true,
            },
          ]}
          compact={true}
          items={assets}
          selectionMode={SelectionMode.none}
        />
      </Panel>
    </>
  );
}

export default Issues;
