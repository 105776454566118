import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { initializeIcons } from "@fluentui/react/lib/Icons";
import AppContext, {
  defaultProfile,
  IDetailsListBasicExampleItem,
  IProfileData,
} from "./components/AppContext";
import CreateItem from "./components/CreateItem";
import ShowQR from "./components/ShowQR";
import DetailAsset from "./components/DetailAsset";
import EditItem from "./components/EditItem";
import Home from "./components/Home";
import { FC, useEffect, useState } from "react";
import ImportItem from "./components/ImportItem";
import Profile from "./components/Profile";
import { useIsAuthenticated  as useIsAuthenticatedMSAL} from "@azure/msal-react";
import { PrimaryButton } from "@fluentui/react";
import { appRoles, idp, idps, loginRequest } from "./authConfig";
import ItemsList from "./components/ItemsList";
import QrCodes from "./components/QrCodes";
import Settings from "./components/Settings";
import Issues from "./components/Issues";
import WorkOrders from "./components/WorkOrders";
import Zones from "./components/Zones";
import Categories from "./components/Categories";
import ImportIssueTypes from "./components/ImportIssueTypes";
import ImportZones from "./components/ImportZones";
import Contractors from "./components/Contractors";
import CreateContractor from "./components/CreateContractor";
import CreateIssue from "./components/CreateIssue";
import Login from "./Login";
import WorkOrderStatuses from "./components/WorkOrderStatuses";

const App: FC<any> = ({ instance }) => {
  initializeIcons();

  const defaultItem: IDetailsListBasicExampleItem = {
    id: 0,
    uuid: "",
    code: "",
    note: "",
  };

  const [selectedItem, setSelectedItem] =
    useState<IDetailsListBasicExampleItem>(defaultItem);
  const [isImportDialogOpen, setIsImportDialogOpen] = useState(false);
  const [isProfileDialogOpen, setIsProfileDialogOpen] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [profileData, setProfileData] = useState<IProfileData>(defaultProfile);
  const [errorMessage, setErrorMessage] = useState("");
  const [isInProgress, setIsInProgress] = useState(false);
  const context = {
    selectedItem: selectedItem,
    setSelectedItem: (item: IDetailsListBasicExampleItem) => {
      setSelectedItem(item);
    },
    isImportDialogOpen: isImportDialogOpen,
    setIsImportDialogOpen: (flag: boolean) => {
      setIsImportDialogOpen(flag);
    },
    isProfileDialogOpen: isProfileDialogOpen,
    setIsProfileDialogOpen: (flag: boolean) => {
      setIsProfileDialogOpen(flag);
    },
    isAuthenticated: isAuthenticated,
    setIsAuthenticated: (flag: boolean) => {
      setIsAuthenticated(flag);
    },
    profileData: profileData,
    setProfileData: (profileData: IProfileData) => {
      setProfileData(profileData);
    },
    errorMessage: errorMessage,
    setErrorMessage: (message: string) => {
      setErrorMessage(message);
    },
    isInProgress: isInProgress,
    setIsInProgress: (isInProgress: boolean) => setIsInProgress(isInProgress),
  };

  const [showLoginPage, setShowLoginPage] = useState(false);

  const handleSignIn = () => {
    if (idp === idps.AAD) {
      instance.loginRedirect(loginRequest)
        .catch((e: any) => {
          console.error(e);
        });
        context.setIsAuthenticated(true);
    } else if (idp === idps.DB) {
      setShowLoginPage(true);
    }
  };

  const handleSignOut = () => {
    instance.logoutRedirect().catch((e: any) => {
      console.error(e);
    });
  };

  // const accounts = instance.getAllAccounts();
  const [isAuthorized, setIsAuthorized] = useState(false);

  const onLoad = async () => {
    if (idp === idps.AAD) {
      const currentAccount: any = instance.getActiveAccount();

      if (currentAccount && currentAccount.idTokenClaims["roles"]) {
        let intersection = [appRoles.Admin, appRoles.Viewer].filter((role: any) =>
          currentAccount.idTokenClaims["roles"].includes(role)
        );

        if (intersection.length > 0) {
          setIsAuthorized(true);
        }
      }
    } else {
      // to be completed for DB idp
      setIsAuthorized(true);
    }
  };

  useEffect(() => {
    onLoad();
  }, [instance]);

  const isAuthenticatedInAad = useIsAuthenticatedMSAL();

  return (
    <AppContext.Provider value={context}>
      {(isAuthenticatedInAad || context.isAuthenticated) ? (
        <>
          <BrowserRouter basename="/">
            {isAuthorized ? (
              <>
                <Home>
                  <Routes>
                    <Route path="/" element={<ItemsList />} />
                    <Route path="/zones" element={<Zones />} />
                    <Route path="/categories" element={<Categories />} />
                    <Route path="categories/:id" element={<Categories />} />
                    <Route path="/contractors" element={<Contractors />} />
                    <Route path="/newContractor" element={<CreateContractor />} />
                    <Route path="/newItem" element={<CreateItem />} />
                    <Route path="/showQR" element={<ShowQR />} />
                    <Route path="/assetDetail" element={<DetailAsset />} />
                    <Route path="/issues" element={<Issues />} />
                    <Route path="/newIssue" element={<CreateIssue />} />
                    <Route path="/editItem" element={<EditItem />} />
                    <Route path="/importAssets" element={<ImportItem />} />
                    <Route path="/importIssueTypes" element={<ImportIssueTypes />} />
                    <Route path="/importZones" element={<ImportZones />} />
                    <Route path="/workOrders" element={<WorkOrders />} />
                    <Route path="/qrCodes" element={<QrCodes />} />
                    <Route path="/settings" element={<Settings />} />
                    <Route path="/workOrderStatuses" element={<WorkOrderStatuses />} />
                  </Routes>
                </Home>
              </>
            ) : (
              <div className="App">
                <p>
                  Your account is not permitted to use this application. Contact
                  administrator or try another account.
                </p>
                <PrimaryButton onClick={handleSignOut}>Sign out</PrimaryButton>
              </div>
            )}
          </BrowserRouter>
          <Profile />
        </>) : (
        <>
          {showLoginPage ? <Login onLoginSuccess={() => { setIsAuthenticated(true) }} /> :
            <div className="App">
              <p>You are not signed in! Please sign in.</p>
              <PrimaryButton onClick={handleSignIn}>Sign in</PrimaryButton>
            </div>}
        </>)}
    </AppContext.Provider>
  );
};

export default App;
