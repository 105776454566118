import { ICommandBarItemProps, IconButton, SelectionMode, Stack, Text, CommandBar } from '@fluentui/react';
import { useContext, useEffect, useState } from 'react'
import { appRoles } from '../authConfig';
import { scrollStackItemStyles } from '../common/styles/StackStyles';
import { getWorkOrders, getWorkOrderStatuses, IPagedCollection, IResendWorkOrderResponse, IWorkOrder, IWorkOrderStatuses, resendWorkOrder } from '../services/assetServices'
import AppContext from './AppContext';
import WorkItems from './WorkItems';

const WorkOrders = () => {
  const context = useContext(AppContext);

  const [workOrderStatuses, setWorkOrderStatuses] = useState<IWorkOrderStatuses[]>([]);
  const [allItems, setWorkOrders] = useState<IWorkOrder[]>([]);
  const [isLastPage, setIsLastPage] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState({ no: 1, search: "" });
  const [shimmered, setShimmered] = useState(false);

  useEffect(() => {
    const abortController = new AbortController();

    getWorkOrderStatuses(abortController)
      .then((data: IWorkOrderStatuses[]) => {
        setWorkOrderStatuses(data);
        getWorkOrders(abortController, 10, page.no)
          .then((data: IPagedCollection<IWorkOrder>) => {
            setWorkOrders(data.items.map(contractor => {
              const newIssue: IWorkOrder = {
                ...contractor,
                createdAt: new Date(contractor.createdAt + 'Z').toLocaleString()
              };
              return newIssue;
            }))
            setIsLastPage(data.isLastPage);
            setPageCount(data.pageCount);
            setShimmered(false);
          })
          .catch((error: { message: string }) => {
            console.error("Error:", error);
            context.setErrorMessage(error.message);
          })
          .finally(() => {
            context.setIsInProgress(false);
          });
      })
      .catch((error: { message: string }) => {
        console.error("Error:", error);
        context.setErrorMessage(error.message);
      })
      .finally(() => {
        context.setIsInProgress(false);
      });
    return () => {
      abortController.abort();
    }
  }, [page.no, page.search])

  const [selectedWorkOrders, setSelectedWorkOrders] = useState<IWorkOrder[]>([]);
  const [resending, setResending] = useState(false);
  const _items: ICommandBarItemProps[] = [
    {
      key: "resend",
      text: "Resend",
      iconProps: { iconName: "FabricSyncFolder" },
      disabled:
        !context.profileData.roles.includes(appRoles.Admin) ||
        selectedWorkOrders?.length === 0 || resending,
      onClick: () => {
        const abortController = new AbortController();
        setResending(true);
        resendWorkOrder(abortController, selectedWorkOrders[0].id)
          .then((response: IResendWorkOrderResponse) => {
            alert(`Work order ${response.workOrderId} resent`);
          })
          .catch((error) => context.setErrorMessage(error.detail))
          .finally(() => setResending(false));
      },
    },
  ];

  return (
    <>
      <Stack>
        <CommandBar
          items={_items}
          ariaLabel="Items actions"
          primaryGroupAriaLabel="Items actions"
          farItemsGroupAriaLabel="More actions"
        />
        <Stack.Item align="center" styles={scrollStackItemStyles}>
          <WorkItems issueWorkOrders={allItems} workOrderStatuses={workOrderStatuses}
            issue={undefined}
            listSelectionMode={SelectionMode.single}
            selection={selectedWorkOrders}
            setSelection={setSelectedWorkOrders}
            shimmered={shimmered}></WorkItems>
        </Stack.Item>
        <Stack horizontal horizontalAlign="space-between">
          <Stack.Item grow={1} align="center">
            <IconButton
              iconProps={{ iconName: "DoubleChevronLeft" }}
              disabled={page.no === 1}
              onClick={() => setPage({ no: 1, search: page.search })}
            />
            <IconButton
              iconProps={{ iconName: "ChevronLeft" }}
              disabled={page.no === 1}
              onClick={() => setPage({ no: page.no - 1, search: page.search })}
            />
            <Text>
              {page.no} of {pageCount}
            </Text>
            <IconButton
              iconProps={{ iconName: "ChevronRight" }}
              disabled={isLastPage}
              onClick={() => setPage({ no: page.no + 1, search: page.search })}
            />
            <IconButton
              iconProps={{ iconName: "DoubleChevronRight" }}
              disabled={isLastPage}
              onClick={() => setPage({ no: pageCount, search: page.search })}
            />
          </Stack.Item>
        </Stack>
      </Stack>
    </>
  )
}

export default WorkOrders