import "./CreateItem.css";
import { useContext, useRef, useState } from "react";
import {
  DefaultButton,
  PrimaryButton,
  Label,
  TagPicker,
  ITag,
  IBasePickerSuggestionsProps,
  IBasePicker,
  Separator,
} from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { ITextField, TextField } from "@fluentui/react/lib/TextField";
import { Stack, IStackProps, IStackStyles } from "@fluentui/react/lib/Stack";
import { useNavigate } from "react-router-dom";
import AppContext from "./AppContext";
import ConfirmDialog from "../common/ConfirmDialog";
import { createContractor, ICreateContractorRequest, ICreateContractorResponse, searchWorkOrders } from "../services/assetServices";
import React from "react";

const CreateContractor = () => {
  // const [categories, setCategories] = useState<IContractorCategory[]>();
  const navigate = useNavigate();

  const codeInput = useRef<ITextField>(null);
  const nameInput = useRef<ITextField>(null);
  const workOrdersInput = useRef<IBasePicker<ITag>>(null);
  const assignmentsDescriptionInput = useRef<ITextField>(null);

  const [state, setState] = useState("");
  const isSaved = useRef(false);

  const context = useContext(AppContext);

  const handleSubmit = (event: any) => {
    if (event) {
      event.preventDefault();
    }
    const data: ICreateContractorRequest = {
      code: (codeInput?.current as unknown as ITextField)?.value ?? '',
      name: (nameInput?.current as unknown as ITextField)?.value ?? '',
      workOrdersIds: (workOrdersInput?.current as unknown as IBasePicker<ITag>)
        .items?.map(item => (Number)(item.key)) ?? [],
      otherInfo: '',
      assignmentsDescription: (assignmentsDescriptionInput?.current as unknown as ITextField).value ?? '',
    };
    const abortController = new AbortController();
    context.setIsInProgress(true);
    createContractor(abortController, data)
      .then((data: ICreateContractorResponse) => {
        setState(`${data.contractorId ? "Contractor created." : "Contractor with this code already exists"} Contractor ID:${data.contractorId}`);
        isSaved.current = true;
      })
      .catch((error: any) => {
        console.error("Error:", error);
        context.setErrorMessage(error.message);
      })
      .finally(() => {
        context.setIsInProgress(false);
      });
  };

  const stackTokens = { childrenGap: 50 };
  const stackStyles: Partial<IStackStyles> = { root: { width: 650 } };
  const columnProps: Partial<IStackProps> = {
    tokens: { childrenGap: 15 },
    styles: { root: { width: 300 } },
  };

  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] =
    useBoolean(false);

  const labelColumnStyle = {
    width: 100,
    textAlign: 'left' as const
  };
  const valueColumnStyle = {
    width: 200,
  }

  const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
    suggestionsHeaderText: 'WorkOrders',
    noResultsFoundText: 'No workorder found',
  };

  const listContainsTagList = (tag?: ITag, tagList?: ITag[]) => {
    if (!tagList || !tagList.length || tagList.length === 0 || tag === undefined) {
      return false;
    }
    return tagList.some(compareTag => compareTag.key === tag.key);
  };

  const onItemSelected = React.useCallback((item?: ITag): ITag | null => {
    if (workOrdersInput.current && listContainsTagList(item, workOrdersInput.current.items)) {
      return null;
    }
    return item ?? null;
  }, []);

  async function onResolveSuggestions(filter: string): Promise<ITag[]> {
    const abortController = new AbortController();
    if (!filter || filter.length < 3) {
      return [];
    }
    const suggestions = await searchWorkOrders(abortController, filter);
    if (suggestions.length === 0) {
      return [];
    }
    return suggestions.map(s => ({ key: s.id, name: s.description }));
  }

  const getTextFromItem = (item: ITag) => item.key.toString();

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div style={{ padding: "10px" }}>
          <div className="form-group">
            <Stack horizontal tokens={stackTokens} styles={stackStyles}>
              <Stack {...columnProps}>
                <Stack tokens={{ childrenGap: 5 }}>
                  <Stack horizontal>
                    <Label style={labelColumnStyle}>Name: </Label>
                    <TextField style={valueColumnStyle} componentRef={nameInput} readOnly={isSaved.current} />
                  </Stack>
                  <Stack horizontal>
                    <Label style={labelColumnStyle}>Code: </Label>
                    <TextField style={valueColumnStyle} componentRef={codeInput} readOnly={isSaved.current} />
                  </Stack>
                  <Separator>Assignments</Separator>
                  <Stack horizontal>
                    <Label style={labelColumnStyle}>Work orders: </Label>
                    <TagPicker
                      styles={{root: valueColumnStyle}}
                      removeButtonAriaLabel="Remove"
                      selectionAriaLabel="Selected work orders"
                      onResolveSuggestions={onResolveSuggestions}
                      getTextFromItem={getTextFromItem}
                      pickerSuggestionsProps={pickerSuggestionsProps}
                      itemLimit={5}
                      // this option tells the picker's callout to render inline instead of in a new layer
                      pickerCalloutProps={{ doNotLayer: true }}
                      componentRef={workOrdersInput}
                      onItemSelected={onItemSelected}
                    />
                  </Stack>
                  <Stack horizontal>
                    <Label style={labelColumnStyle}>Description: </Label>
                    <TextField style={valueColumnStyle} componentRef={assignmentsDescriptionInput} readOnly={isSaved.current} />
                  </Stack>
                </Stack>
                <Stack.Item align="center">
                  <Stack horizontal tokens={stackTokens}>
                    <PrimaryButton type="submit">Save</PrimaryButton>
                    <DefaultButton onClick={() => {
                      if (!isSaved.current) {
                        showModal();
                      } else {
                        navigate("/");
                      }
                    }}>Cancel</DefaultButton>
                  </Stack>
                </Stack.Item>
              </Stack>
            </Stack>

            <ConfirmDialog isModalOpen={isModalOpen} hideModal={hideModal}
              message="All your unsaved changes would be lost."
              onYesClick={() => navigate("/")} />

          </div>
        </div>
      </form>{" "}
      <span>{state}</span>

    </div>
  );
};

export default CreateContractor;
